<template>
  <div>
    <div
      class="mb-3"
      :style="{
        maxWidth: '570px',
        paddingBottom: '50px',
      }"
    >
      <list
        :cases="{
          title: 'Комплект документов для участника оценки',
          add: 'Добавить документ',
        }"
        :data="docs.PARTICIPANT"
        edit
        add
        remove
        :help="helpTexts.PARTICIPANT"
        @add="switchToggle(true, 'PARTICIPANT')"
        @edit="selectDoc"
        @remove="removeDoc"
      >
      </list>

      <list
        :cases="{
          title: 'Комплект документов для эксперта по оценке',
          add: 'Добавить документ',
        }"
        :data="docs.EXPERT"
        edit
        add
        remove
        :help="helpTexts.EXPERT"
        @add="switchToggle(true, 'EXPERT')"
        @edit="selectDoc"
        @remove="removeDoc"
      ></list>

      <list
        :cases="{
          title: 'Комплект документов для руководителя',
          add: 'Добавить документ',
        }"
        :data="docs.LEADER"
        edit
        add
        remove
        :help="helpTexts.LEADER"
        @add="switchToggle(true, 'LEADER')"
        @edit="selectDoc"
        @remove="removeDoc"
      ></list>

      <list
        :cases="{
          title: 'Комплект документов для наблюдателя',
          add: 'Добавить документ',
        }"
        :data="docs.OBSERVER"
        edit
        add
        remove
        :help="helpTexts.OBSERVER"
        @add="switchToggle(true, 'OBSERVER')"
        @edit="selectDoc"
        @remove="removeDoc"
      ></list>
    </div>

    <modal :dialog="toggle" :width="toggle.width">
      <SimulationDocsForm
        :profileId="profile.id"
        :editedDoc="doc"
        @close="switchToggle(false)"
        @save="saveDoc"
        :key="doc.id || Date.now()"
      />
    </modal>
  </div>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";
import { useModalToggle, useLinks } from "@/use/helpers";
import SimulationDocsForm from "./SimulationDocsForm";

export default {
  props: {
    profile: Object,
  },
  setup(_, { root }) {
    const store = root.$store;
    const { validLink } = useLinks();

    const helpTexts = {
      PARTICIPANT:
        'Комплект документов будет доступен участнику оценки на вкладке "Документы" в ' +
        "симуляции рабочего дня. Комплект документов так же будет доступен экспертам по " +
        "оценке, руководителям и наблюдателям",
      EXPERT:
        'Комплект документов будет доступен эксперту по оценке на вкладке "Документы" в ' +
        "симуляции рабочего дня. Эксперт по оценке так же будет иметь доступ к документам " +
        "участника оценки",
      LEADER:
        'Комплект документов будет доступен руководителю на вкладке "Документы" в ' +
        "симуляции рабочего дня. Руководитель так же будет иметь доступ к " +
        "документам участника оценки",
      OBSERVER:
        'Комплект документов будет доступен наблюдателю на вкладке "Документы" в ' +
        "симуляции рабочего дня. Наблюдатель так же будет иметь доступ к " +
        "документам участника оценки",
    };

    const { toggle, switchToggle } = useModalToggle();
    const doc = ref({});
    watch(toggle, val => {
      if (!val.isOpen) {
        doc.value = {};
      }
    });

    const docs = computed(() => {
      return {
        PARTICIPANT: _.profile.simulation.docs.filter(
          el => el.role == "PARTICIPANT"
        ),
        EXPERT: _.profile.simulation.docs.filter(el => el.role == "EXPERT"),
        LEADER: _.profile.simulation.docs.filter(el => el.role == "LEADER"),
        OBSERVER: _.profile.simulation.docs.filter(el => el.role == "OBSERVER"),
      };
    });

    const selectDoc = item => {
      doc.value = _.profile.simulation.docs.find(el => el.id == item.id);
      switchToggle(true);
    };

    const removeDoc = item =>
      store.dispatch("removeProfileDoc", {
        profileId: _.profile.id,
        docId: item.id,
      });

    const saveDoc = data =>
      store.dispatch("saveProfileDoc", {
        profileId: _.profile.id,
        name: data.name,
        link: validLink(data.link),
        role: data.role || toggle.view,
        documentId: data.id,
      });

    return {
      toggle,
      switchToggle,
      helpTexts,
      docs,
      doc,
      selectDoc,
      saveDoc,
      removeDoc,
    };
  },
  components: {
    SimulationDocsForm,
  },
};
</script>
