export default function useReport() {
  const reportModel = {
    id: null,
    role: null,
    profileId: null,
    title: "Результаты оценки готовы!",
    introStatement:
      "Центр оценки - это метод оценки компетенций, во время " +
      "которого предлагалось выполнить задачи, которые похожи на реальную рабочую деятельность.\n\n" +
      "Результаты оценки приведены в этом отчете и включают в себя итоговые оценки " +
      "по компетенциям и отдельно по индикаторам, комментарии по сильным сторонам и " +
      "зонам развития, а так же рекомендации по развитию.",
    assessmentConclusionSectionName: "Выводы по итогам центра оценки",
    competencesConclusionSectionName: "Выводы по компетенциям",
    exceedsExpectationsConclusion:
      "Во время центра оценки большинство компетенций проявлялось стабильно на высоком " +
      "уровне, превосходящем требования к должности. Рекомендуем рассматривать итоги " +
      "центра оценки в совокупности с результатами других оценочных процедур.",
    meetExpectationsConclusion:
      "Во время центра оценки большинство компетенций проявлялось на уровне, который " +
      "соответствует требованиям к должности. Рекомендуем рассматривать итоги центра " +
      "оценки в совокупности с результатами других оценочных процедур.",
    doNotMeetExpectationsConclusion:
      "Во время центра оценки большинство компетенций проявлялось на уровне, который не " +
      "соответствует требованиям к должности. Рекомендуем рассматривать итоги центра оценки " +
      "в совокупности с результатами других оценочных процедур.",
    completionStatement:
      "Спасибо за ознакомление с отчётом!\n\n" +
      "При появлении вопросов Вы можете обратиться к администратору оценочной сессии.",
    docName: null,
    docLink: null,
  };

  return {
    reportModel,
  };
}
