<template>
  <div>
    <SimulationDate
      :date="profile.simulation.simulationDate"
      :time="profile.simulation.simulationTime"
      :profileId="profile.id"
      @date-saved="simulationDate = $event"
    />
    <SimulationTime
      :time="profile.simulation.simulationTime"
      :date="simulationDate"
      :profileId="profile.id"
    />
    <div class="py-3 mb-3">
      <p class="subtitle mb-1 primary--text">Адресаты:</p>
      <ListForm
        :data="recipients"
        tab="Recipients"
        inputLabel="Адресат"
        @save="addRecipient"
        :minHeight="0"
      />
    </div>

    <SimulationEmails
      :emails="profile.simulation.emails"
      :profileId="profile.id"
    />
    <SimulationEvents
      :events="profile.simulation.events"
      :profileId="profile.id"
    />
    <SimulationDocs :profile="profile" />
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import SimulationEvents from "./simulation/events/SimulationEvents";
import SimulationEmails from "./simulation/emails/SimulationEmails";
import SimulationDate from "./simulation/datetime/SimulationDate";
import SimulationTime from "./simulation/datetime/SimulationTime";
import SimulationDocs from "./simulation/docs/SimulationDocs";
import ListForm from "@/components/admin/assets/ListForm";

export default {
  props: ["profile"],
  setup(_, { root }) {
    const store = root.$store;

    const simulationDate = ref(_.profile.simulation.simulationDate);

    const recipients = computed(() => store.getters.getRecipients);
    const addRecipient = data => {
      if (!store.getters.getRecipientsNames.includes(data))
        store.dispatch("createRecipient", data);
      else root.$toast.error("Адресат уже существует: " + data);
    };

    return {
      simulationDate,
      recipients,
      addRecipient,
    };
  },
  components: {
    SimulationEvents,
    SimulationEmails,
    SimulationDate,
    SimulationTime,
    SimulationDocs,
    ListForm,
  },
};
</script>
