import { ref, computed } from "@vue/composition-api";

export default function useIndicators(competences = [], autoselect = true) {
  if (!competences.length) {
    competences.push({
      indicators: [{}],
    });
  }

  const selectedIndicator = ref({});
  const selectedCompetence = ref({});

  const selectIndicator = (indicator, competence) => {
    selectedCompetence.value = competence;
    selectedIndicator.value = indicator;
  };

  if (autoselect) {
    selectIndicator(competences[0].indicators[0], competences[0]);
  }

  const isFirstIndicator = computed(() => {
    let competenceIndex = competences.findIndex(
      el => el == selectedCompetence.value
    );
    let indicatorIndex =
      selectedCompetence.value.indicators &&
      selectedCompetence.value.indicators.findIndex(
        el => el == selectedIndicator.value
      );
    return competenceIndex <= 0 && indicatorIndex <= 0;
  });

  const isLastIndicator = computed(() => {
    let competenceIndex = competences.findIndex(
      el => el == selectedCompetence.value
    );
    let indicatorIndex =
      selectedCompetence.value.indicators &&
      selectedCompetence.value.indicators.findIndex(
        el => el == selectedIndicator.value
      );

    return (
      competenceIndex == competences.length - 1 &&
      indicatorIndex == selectedCompetence.value.indicators.length - 1
    );
  });

  const getNextIndicator = () => {
    let competenceIndex = competences.findIndex(
      el => el.id == selectedCompetence.value.id
    );
    let indicatorIndex = selectedCompetence.value.indicators.findIndex(
      el => el.id == selectedIndicator.value.id
    );

    while (competences[competenceIndex]) {
      indicatorIndex++;
      let nextIndicator =
        competences[competenceIndex].indicators[indicatorIndex];

      if (nextIndicator) {
        selectIndicator(nextIndicator, competences[competenceIndex]);
        return;
      } else {
        competenceIndex++;
        indicatorIndex = -1;
      }
    }
  };
  const getPrevIndicator = () => {
    let competenceIndex = competences.findIndex(
      el => el.id == selectedCompetence.value.id
    );
    let indicatorIndex = selectedCompetence.value.indicators.findIndex(
      el => el.id == selectedIndicator.value.id
    );

    while (competences[competenceIndex]) {
      indicatorIndex--;
      let prevIndicator =
        competences[competenceIndex].indicators[indicatorIndex];

      if (prevIndicator) {
        selectIndicator(prevIndicator, competences[competenceIndex]);
        return;
      } else {
        competenceIndex--;
        if (competences[competenceIndex]) {
          indicatorIndex = competences[competenceIndex].indicators.length;
        }
      }
    }
  };

  const isFirstCompetence = computed(() => {
    let competenceIndex = competences.findIndex(
      el => el.id == selectedCompetence.value.id
    );

    return competenceIndex == 0;
  });

  const isLastCompetence = computed(() => {
    let competenceIndex = competences.findIndex(
      el => el.id == selectedCompetence.value.id
    );

    return competenceIndex == competences.length - 1;
  });

  const getNextCompetence = () => {
    let competenceIndex = competences.findIndex(
      el => el.id == selectedCompetence.value.id
    );

    competenceIndex++;

    if (competenceIndex < competences.length) {
      selectIndicator(
        competences[competenceIndex].indicators[0],
        competences[competenceIndex]
      );
    }
  };

  const getPrevCompetence = () => {
    let competenceIndex = competences.findIndex(
      el => el.id == selectedCompetence.value.id
    );

    competenceIndex--;

    if (competenceIndex >= 0) {
      selectIndicator(
        competences[competenceIndex].indicators[0],
        competences[competenceIndex]
      );
    }
  };

  return {
    selectedIndicator,
    selectedCompetence,
    selectIndicator,
    isFirstIndicator,
    isLastIndicator,
    getNextIndicator,
    getPrevIndicator,
    isFirstCompetence,
    isLastCompetence,
    getNextCompetence,
    getPrevCompetence,
  };
}
