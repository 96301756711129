<template>
  <div>
    <div class="d-flex justify-space-between">
      <text-btn small icon="mdi-arrow-left" @click="$emit('back')"
        >Вернуться к заданию</text-btn
      >
    </div>
    <div>
      <InstructionsForm
        :editedNote="data"
        @close="$emit('back')"
        @save="
          $emit('instruction-saved', {
            data: $event,
            isEdit: !!data,
          })
        "
      />
    </div>
  </div>
</template>

<script>
import InstructionsForm from "./InstructionsForm";

export default {
  props: {
    task: Object,
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    return {};
  },
  components: {
    InstructionsForm,
  },
};
</script>
