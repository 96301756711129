<template>
  <div>
    <v-form class="form" ref="form">
      <p class="subtitle primary--text text-center mb-4">
        Инструкция для эксперта
      </p>
      <div class="mb-3">
        <p class="subtitle mb-1">Название:</p>
        <v-text-field
          hide-details
          outlined
          v-model="note.name"
          type="text"
          :required="true"
          :rules="[val => !!val || '']"
          label="Название инструкции"
        ></v-text-field>
      </div>
      <div class="mb-3">
        <p class="subtitle mb-1">Текст:</p>
        <v-textarea
          hide-details
          outlined
          no-resize
          v-model="note.text"
          type="text"
          :required="true"
          :rules="[val => !!val || '']"
          label="Текст инструкции"
        ></v-textarea>
      </div>
      <div class="mb-3">
        <p class="subtitle mb-1">Вложение:</p>
        <v-text-field
          class="mb-3"
          hide-details
          outlined
          v-model="note.attachmentName"
          type="text"
          label="Название вложения"
        ></v-text-field>
        <v-text-field
          hide-details
          outlined
          v-model="note.attachmentLink"
          type="text"
          label="Ссылка"
        ></v-text-field>
      </div>

      <div class="d-flex justify-space-between mt-7">
        <confirm
          :extDialog="isResetConfirmShown"
          @confirm="cancel"
          @cancel="isResetConfirmShown = false"
          text="Вы действительно хотите сохранить изменения?"
        >
          <v-btn
            class="white--text primary flex-grow-1 mr-5"
            @click="isResetConfirmShown = true"
          >
            Отменить
          </v-btn>
        </confirm>
        <v-btn
          class="white--text primary flex-grow-1"
          @click="$refs.form.validate() && save()"
        >
          Добавить
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  props: {
    editedNote: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { emit }) {
    const note = ref({ ..._.editedNote });

    const cancel = () => {
      emit("close");
    };

    const save = () => {
      emit("save", note.value);
      cancel();
    };

    const isResetConfirmShown = ref(false);

    return {
      note,
      cancel,
      save,
      isResetConfirmShown,
    };
  },
};
</script>
