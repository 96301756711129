<template>
  <v-form class="form" ref="form">
    <div class="mb-3">
      <p class="subtitle mb-1">Название документа:</p>
      <v-text-field
        outlined
        hide-details
        v-model="doc.name"
        type="text"
        :required="true"
        :rules="[val => !!val || '']"
        label="Введите название документа"
      ></v-text-field>
    </div>
    <div class="mb-7">
      <p class="subtitle mb-1">Ссылка на документ:</p>
      <v-text-field
        outlined
        hide-details
        v-model="doc.link"
        type="text"
        :required="true"
        :rules="[val => !!val || '']"
        label="Вставьте ссылку на документ"
      ></v-text-field>
    </div>
    <div class="d-flex justify-space-between">
      <v-btn class="white--text primary flex-grow-1 mr-5" @click="cancel">
        Отменить
      </v-btn>
      <v-btn class="white--text primary flex-grow-1" @click="save">
        Сохранить
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: {
    editedDoc: {
      type: Object,
      default: () => {
        return {};
      },
    },
    profileId: String,
  },
  setup(_, { refs, emit }) {
    const changed = ref(false);
    const doc = ref({ ..._.editedDoc });
    watch(doc.value, () => {
      changed.value = true;
    });

    const cancel = () => {
      if (!changed.value) {
        emit("close");
      } else {
        changed.value = false;
        doc.value = { ..._.editedDoc };
        watch(doc.value, () => {
          changed.value = true;
        });
      }
    };

    const save = () => {
      if (!refs.form.validate()) return;

      emit("save", doc.value);
      emit("close");
    };

    return {
      changed,
      doc,
      cancel,
      save,
    };
  },
};
</script>
