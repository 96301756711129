<template>
  <div>
    <v-form class="form" ref="form" style="max-width: 100%">
      <p class="subtitle primary--text text-center mb-4">
        Пожалуйста введите информацию об индикаторе
        {{ competence.indexNumber }}.{{ editedIndicator.indexNumber }}:
      </p>
      <p class="subtitle primary--text text-center mb-4">
        Уровни проявления индикатора в задании:
      </p>
      <div class="scroll-wrapper" style="overflow-y: auto">
        <div
          v-for="item in editedIndicator.levelMarks"
          :key="item.level"
          class="mb-3"
        >
          <p class="subtitle mb-1">
            {{
              item.level === "Нет оценки"
                ? `Описание для "${item.level}":`
                : typeof +item.level === "number"
                ? `Описание на оценку ${item.level}:`
                : `Описание, если нет данных ("нд")`
            }}
          </p>
          <v-textarea
            hide-details
            outlined
            v-model="item.description"
            type="text"
            label="Введите описание индикатора"
            no-resize
          ></v-textarea>
        </div>
      </div>

      <div class="d-flex justify-space-between mt-7">
        <confirm
          :extDialog="isResetConfirmShown"
          @confirm="cancel"
          @cancel="isResetConfirmShown = false"
          text="Отменить изменения?"
        >
          <v-btn
            class="white--text primary flex-grow-1 mr-5"
            @click="isResetConfirmShown = true"
          >
            Отменить
          </v-btn>
        </confirm>
        <v-btn class="white--text primary flex-grow-1" @click="save">
          Добавить
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  props: {
    indicator: Object,
    competence: Object,
  },
  setup(_, { emit }) {
    const editedIndicator = ref(JSON.parse(JSON.stringify(_.indicator)));

    const cancel = () => {
      emit("close");
    };

    const save = () => {
      emit("save", {
        competence: _.competence,
        indicator: editedIndicator.value,
      });
      emit("close");
    };

    const isResetConfirmShown = ref(false);

    return {
      editedIndicator,
      cancel,
      save,
      isResetConfirmShown,
    };
  },
};
</script>
