<template>
  <v-form ref="form">
    <div class="mb-5">
      <p class="subtitle mb-2 primary--text">Заголовок анкеты:</p>
      <v-text-field
        hide-details
        outlined
        type="text"
        :required="true"
        :rules="[val => !!val || '']"
        label="Введите заголовок анкеты"
        v-model="questionnaire.title"
      ></v-text-field>
    </div>
    <div class="mb-5">
      <p class="subtitle mb-2 primary--text">Вводная информация по анкете:</p>
      <v-textarea
        hide-details
        outlined
        dense
        :no-resize="true"
        label="Введите текст"
        v-model="questionnaire.introStatement"
      ></v-textarea>
    </div>
    <div class="mb-3">
      <v-card
        class="d-flex justify-space-between align-center"
        v-for="questionItem in questions"
        :key="questionItem.indexNumber"
      >
        <div>
          <v-card-title class="pb-0 mb-2">{{
            questionItem.question
          }}</v-card-title>
          <v-card-subtitle class="d-flex align-center" style="font-size: 15px">
            <v-checkbox
              style="pointer-events: none"
              color="success"
              input-value="true"
              value
              hide-details
              class="mt-0"
            ></v-checkbox>
            {{ types[questionItem.type] }}
          </v-card-subtitle>
        </div>
        <div class="flex-shrink-0">
          <v-btn
            icon
            @click="
              selectQuestion(questionItem.indexNumber),
                switchToggle(true, 'edit')
            "
          >
            <v-icon color="#767676" style="font-size: 16px">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteQuestion(questionItem.indexNumber)">
            <v-icon color="#767676" style="font-size: 16px">mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-card>
    </div>
    <div class="mb-3">
      <text-btn icon="mdi-plus" @click="switchToggle(true, 'create')">
        Добавить вопрос</text-btn
      >
    </div>
    <div class="mb-5">
      <p class="subtitle mb-2 primary--text">Завершение анкеты:</p>
      <v-textarea
        hide-details
        outlined
        dense
        :no-resize="true"
        label="Введите текст"
        v-model="questionnaire.completionStatement"
      ></v-textarea>
    </div>
    <div class="d-flex justify-space-between mt-1 mb-5">
      <v-btn @click="cancel" class="white--text primary flex-grow-1 mr-5">
        Отменить
      </v-btn>
      <confirm
        :extDialog="saveConfirm"
        text="Сохранить изменения?"
        @confirm="updateQuestionnaire(questionnaire)"
        @cancel="saveConfirm = false"
      >
        <v-btn
          class="white--text primary flex-grow-1"
          @click="$refs.form.validate() && isChanged && (saveConfirm = true)"
        >
          Сохранить
        </v-btn>
      </confirm>
    </div>
    <modal :dialog="toggle">
      <QuestionnaireForm
        v-if="toggle.view === 'create'"
        @question-saved="saveQuestion"
        @close="switchToggle(false, 'create')"
        type="create"
        :questionData="newQuestion"
      />
      <QuestionnaireForm
        v-if="toggle.view === 'edit'"
        @question-saved="saveQuestion"
        @close="switchToggle(false, 'edit')"
        :questionData="currentQuestion"
        type="edit"
      />
    </modal>
  </v-form>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";
import { useModalToggle } from "@/use/helpers";
import QuestionnaireForm from "./questionnaire/QuestionnaireForm";

export default {
  props: ["profile"],
  setup(_, { root }) {
    const { toggle, switchToggle } = useModalToggle();
    const saveConfirm = ref(false);
    const isChanged = ref(false);
    const questionnaire = ref({ ..._.profile.questionnaire });
    const questions = ref(questionnaire.value.questions);
    const currentQuestion = ref({});

    const types = {
      OPEN: "Открытый ответ",
      YES_NO: "Да, Нет, Затрудняюсь ответить",
      SCALE: "Выбор балла от 1 до 10",
    };

    const newQuestion = computed(() => {
      return {
        type: "OPEN",
        question: "",
        indexNumber: questions.value.length + 1,
      };
    });

    watch(questionnaire.value, () => {
      isChanged.value = true;
    });

    const selectQuestion = idx => {
      currentQuestion.value = questions.value.find(q => q.indexNumber === idx);
    };

    const cancel = () => {
      if (!isChanged.value) {
        return;
      } else {
        questionnaire.value = { ..._.profile.questionnaire };
        isChanged.value = false;
        watch(questionnaire.value, () => {
          isChanged.value = true;
        });
      }
    };

    const updateQuestionnaire = data => {
      data.profileId = _.profile.id;
      data.questions = questions;
      root.$store.dispatch("updateQuestionnaire", data);

      isChanged.value = false;
      saveConfirm.value = false;
      isChanged.value = false;
      watch(questionnaire.value, () => {
        isChanged.value = true;
      });
    };

    const saveQuestion = (data, type) => {
      if (type === "edit") {
        const index = questions.value.findIndex(
          q => q.indexNumber === data.indexNumber
        );
        questions.value[index] = data;
      } else {
        questions.value.push(data);
      }
    };

    const deleteQuestion = idx => {
      questions.value.splice(idx, 1);
      questions.value = questions.value.map((q, i) => {
        q.indexNumber = i + 1;
        return q;
      });
    };

    return {
      toggle,
      switchToggle,
      questionnaire,
      updateQuestionnaire,
      saveConfirm,
      isChanged,
      cancel,
      saveQuestion,
      selectQuestion,
      currentQuestion,
      questions,
      deleteQuestion,
      newQuestion,
      types,
    };
  },
  components: {
    QuestionnaireForm,
  },
};
</script>
