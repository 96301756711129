var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedSubtab)?_c('div',[_c(_vm.selectedSubtab.name,{tag:"component",attrs:{"task":_vm.editedTask,"data":_vm.selectedSubtab.selected},on:{"back":function($event){_vm.selectedSubtab = null},"instruction-saved":_vm.updateInstruction}})],1):_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('text-btn',{attrs:{"small":"","icon":"mdi-arrow-left"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("К списку заданий")])],1),_c('v-form',{ref:"form",staticClass:"form"},[_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Название:")]),_c('v-text-field',{attrs:{"hide-details":"","outlined":"","type":"text","required":true,"rules":[function (val) { return !!val || ''; }],"label":"Введите название задания"},model:{value:(_vm.editedTask.name),callback:function ($$v) {_vm.$set(_vm.editedTask, "name", $$v)},expression:"editedTask.name"}})],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Краткое содержание:")]),_c('v-textarea',{attrs:{"hide-details":"","outlined":"","no-resize":"","type":"text","required":true,"rules":[function (val) { return !!val || ''; }],"label":"Введите краткое содержание задания"},model:{value:(_vm.editedTask.description),callback:function ($$v) {_vm.$set(_vm.editedTask, "description", $$v)},expression:"editedTask.description"}})],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Письма:")]),_c('v-select',{staticClass:"chip-wrap",attrs:{"hide-details":"","dense":"","outlined":"","item-text":"title","item-value":"id","items":_vm.simulation.emails,"label":"Выбрать письма","multiple":"","chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]}}]),model:{value:(_vm.editedTask.emailIdList),callback:function ($$v) {_vm.$set(_vm.editedTask, "emailIdList", $$v)},expression:"editedTask.emailIdList"}})],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Выводить ответ на письмо:")]),_c('v-select',{attrs:{"hide-details":"","dense":"","outlined":"","item-text":"title","item-value":"id","items":_vm.simulation.emails,"label":"Выберите письмо, ответ на которое будет оценен в задании"},model:{value:(_vm.editedTask.generalEmailId),callback:function ($$v) {_vm.$set(_vm.editedTask, "generalEmailId", $$v)},expression:"editedTask.generalEmailId"}})],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Встречи:")]),_c('v-select',{staticClass:"chip-wrap",attrs:{"hide-details":"","dense":"","outlined":"","item-text":"title","item-value":"id","items":_vm.simulation.events,"label":"Выбрать встречи","multiple":"","chips":"","validate-on-blur":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]}}]),model:{value:(_vm.editedTask.meetingIdList),callback:function ($$v) {_vm.$set(_vm.editedTask, "meetingIdList", $$v)},expression:"editedTask.meetingIdList"}})],1),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v("Инструкции для эксперта:")]),(_vm.editedTask.expertInstructions)?_c('v-card',{staticClass:"form mb-1",staticStyle:{"max-height":"300px","overflow":"auto"},attrs:{"elevation":"3"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.editedTask.expertInstructions),function(item,idx){return _c('v-list-item',{key:idx,staticClass:"brd-b"},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('p',{staticStyle:{"max-width":"calc(100% - 80px)"}},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"flex-shrink-0"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.editInstruction(idx),
                          (_vm.selectedSubtab = _vm.subtabs.find(
                            function (el) { return el.name == 'instructions'; }
                          ))}}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"#767676"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeInstruction(idx)}}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"#767676"}},[_vm._v("mdi-delete")])],1)],1)])])],1)}),1)],1):_vm._e(),_c('text-btn',{attrs:{"icon":"mdi-plus"},on:{"click":function($event){_vm.selectedSubtab = _vm.subtabs.find(function (el) { return el.name == 'instructions'; })}}},[_vm._v("Добавить инструкцию")])],1),_c('div',[_c('p',{staticClass:"subtitle primary--text mb-1"},[_vm._v(" Выбрано индикаторов ("+_vm._s(_vm.editedTask.indicators.length)+") ")]),_c('text-btn',{attrs:{"right":"","icon":"mdi-arrow-right"},on:{"click":function($event){_vm.selectedSubtab = _vm.subtabs.find(function (el) { return el.name == 'indicators'; })}}},[_vm._v(_vm._s(_vm.editedTask.indicators.length ? "Изменить выбор индикаторов" : "Выбрать индикаторы"))])],1),_c('div',{staticClass:"d-flex justify-space-between mt-7"},[_c('confirm',{attrs:{"extDialog":_vm.isResetConfirmShown,"text":"Отменить изменения?"},on:{"confirm":_vm.reset,"cancel":function($event){_vm.isResetConfirmShown = false}}},[_c('v-btn',{staticClass:"white--text primary flex-grow-1 mr-5",on:{"click":function($event){_vm.isNeedConfirm ? (_vm.isResetConfirmShown = true) : _vm.showWarning()}}},[_vm._v(" Отменить ")])],1),_c('confirm',{attrs:{"extDialog":_vm.isSaveConfirmShown,"text":"Вы действительно хотите сохранить изменения?"},on:{"confirm":_vm.save,"cancel":function($event){_vm.isSaveConfirmShown = false}}},[_c('v-btn',{staticClass:"white--text primary flex-grow-1",on:{"click":function($event){_vm.$refs.form.validate() && _vm.isNeedConfirm
                ? (_vm.isSaveConfirmShown = true)
                : _vm.showWarning()}}},[_vm._v(" Сохранить ")])],1)],1)])],1),(_vm.loading)?_c('preloader'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }