<template>
  <v-form class="form" ref="form">
    <div class="mb-3">
      <p class="subtitle mb-2 primary--text">Название профиля:</p>
      <v-text-field
        hide-details
        outlined
        v-model="editedProfile.name"
        type="text"
        :required="true"
        :rules="[val => !!val || '']"
        label="Введите название профиля"
      ></v-text-field>
    </div>

    <div>
      <p class="subtitle mb-2 primary--text">Описание профиля:</p>
      <v-textarea
        class="field-details--hidden"
        outlined
        dense
        v-model="editedProfile.description"
        :no-resize="true"
        label="Введите описание профиля"
      ></v-textarea>
    </div>

    <div>
      <p class="subtitle mb-2 primary--text">
        Продолжительность центра оценки или развития:
      </p>
      <v-card class="pa-3" max-width="600">
        <span
          class="display-1 font-weight-light"
          v-text="editedProfile.sessionDurationMinutes"
        ></span>
        <span class="body-2">минут</span>

        <v-slider
          v-model="editedProfile.sessionDurationMinutes"
          hide-details
          min="60"
          max="720"
        ></v-slider>
      </v-card>
    </div>

    <div class="d-flex justify-space-between mt-7">
      <confirm
        :extDialog="isResetConfirmShown"
        @confirm="reset"
        @cancel="isResetConfirmShown = false"
        text="Отменить изменения?"
      >
        <v-btn
          class="white--text primary flex-grow-1 mr-5"
          @click="isNeedConfirm ? (isResetConfirmShown = true) : showWarning()"
        >
          Отменить
        </v-btn>
      </confirm>

      <confirm
        :extDialog="isSaveConfirmShown"
        @confirm="save"
        @cancel="isSaveConfirmShown = false"
        text="Cохранить изменения?"
      >
        <v-btn
          class="white--text primary flex-grow-1"
          @click="
            $refs.form.validate() && isNeedConfirm
              ? (isSaveConfirmShown = true)
              : showWarning()
          "
        >
          Сохранить
        </v-btn>
      </confirm>
    </div>
  </v-form>
</template>

<script>
import { ref, watch, inject } from "@vue/composition-api";

export default {
  props: ["profile", "isEdit"],
  setup(_, { root, emit }) {
    const store = root.$store;

    const editedProfile = ref({
      name: _.profile.name,
      description: _.profile.description,
      sessionDurationMinutes: _.profile.sessionDurationMinutes || 300,
    });
    const initialData = ref(JSON.parse(JSON.stringify(editedProfile.value)));

    const isResetConfirmShown = ref(false);
    const isSaveConfirmShown = ref(false);

    const reset = () => {
      editedProfile.value = JSON.parse(JSON.stringify(initialData.value));
      isResetConfirmShown.value = false;
      isNeedConfirm.value = false;
    };

    const save = async () => {
      if (_.isEdit)
        await store.dispatch("updateProfileName", {
          id: _.profile.id,
          ...editedProfile.value,
        });
      else {
        await store.dispatch("createProfile", editedProfile.value);
        emit("profile-created", store.getters.getCurrentProfile);
      }
      isSaveConfirmShown.value = false;
      isNeedConfirm.value = false;
    };

    const isNeedConfirm = inject("isNeedConfirm");
    watch(
      () => editedProfile.value,
      val => {
        if (
          val.name !== initialData.value.name ||
          val.description !== initialData.value.description ||
          val.sessionDurationMinutes !==
            initialData.value.sessionDurationMinutes
        )
          isNeedConfirm.value = true;
      },
      { deep: true }
    );
    const saveFunction = inject("saveFunction");
    saveFunction.value = save;
    const showWarning = inject("showWarning");

    return {
      editedProfile,
      initialData, // for testing
      isResetConfirmShown,
      isSaveConfirmShown,
      reset,
      save,
      isNeedConfirm,
      saveFunction,
      showWarning,
    };
  },
};
</script>
