<template>
  <div>
    <div style="position: relative; max-width: 90%" class="mt-8 mx-auto">
      <v-slider
        class="v-slider-thumb--hidden v-slider-labels--top"
        thumb-size="0"
        :step="options.technicalStep"
        :tick-labels="
          createScoreLabels(
            options.technicalScoreFrom,
            options.technicalScoreTo,
            options.technicalStep || 1
          )
        "
        :max="+options.technicalScoreTo - +options.technicalScoreFrom"
        ticks="always"
        tick-size="6"
      ></v-slider>
      <v-slider
        v-model="percentScore"
        class="v-slider--collapsed"
        step="1"
        :max="100"
        track-color="transparent"
      ></v-slider>
      <v-slider
        class="v-slider-labels--top"
        readonly
        step="1"
        :tick-labels="
          createScoreLabels(options.totalScoreFrom, options.totalScoreTo)
        "
        :max="+options.totalScoreTo"
        :min="+options.totalScoreFrom"
        ticks="always"
        tick-size="6"
        v-model="totalScore"
      ></v-slider>
    </div>
    <div>
      <div class="mb-2">Технический балл: {{ techScore }}</div>
      <div>Итоговый балл: {{ totalScore }}</div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "@vue/composition-api";

export default {
  props: ["options"],
  setup(_) {
    const techScore = ref(0);
    const totalScore = ref(0);
    const percentScore = ref(0);
    watchEffect(() => {
      let techStepCount =
        +_.options.technicalScoreTo - +_.options.technicalScoreFrom;
      let tech =
        (techStepCount / 100) * percentScore.value +
        +_.options.technicalScoreFrom;

      techScore.value =
        (_.options.withRoundingToInt
          ? Math.round(tech)
          : Math.round(tech * 10) / 10) || 0;

      let totalStepCount = +_.options.totalScoreTo - +_.options.totalScoreFrom;
      let total =
        (totalStepCount / 100) * percentScore.value +
          +_.options.totalScoreFrom || 0;

      totalScore.value = Math.round(total);
    });

    const createScoreLabels = (start, end, step = 1) => {
      const labels = [];

      step = step == "0" || !step ? 1 : +step;

      for (let i = +start; i <= +end; i += step) {
        labels.push(String(i));
      }

      return labels;
    };

    return {
      percentScore,
      techScore,
      totalScore,
      createScoreLabels,
    };
  },
};
</script>

<style lang="scss" scoped></style>
