<template>
  <div style="max-width: 570px">
    <v-card
      :style="{
        minHeight: minHeight + 'px',
        maxHeight: '265px',
        overflowY: 'auto',
      }"
    >
      <v-list dense class="py-0">
        <v-list-item class="brd-b" v-for="item in data" :key="item.id">
          <v-list-item-content>
            <div
              style="max-width: 100%"
              class="d-flex justify-space-between align-center"
            >
              <p style="max-width: calc(100% - 80px); line-height: 1.5rem">
                {{ item.name }}
              </p>

              <div class="flex-shrink-0">
                <v-btn icon @click.stop="editItem(item.id)">
                  <v-icon color="#767676" style="font-size: 16px"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn
                  icon
                  @click.stop="(selectedId = item.id), (confirmDialog = true)"
                >
                  <v-icon color="#767676" style="font-size: 16px"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <confirm
        v-show="false"
        text="Подтвердите удаление"
        :extDialog="confirmDialog"
        @cancel="confirmDialog = false"
        @confirm="removeItem(selectedId), (confirmDialog = false)"
      >
      </confirm>
    </v-card>
    <div class="d-flex mt-3 align-center">
      <div class="flex-grow-1 mr-3">
        <v-text-field
          class="pa-0"
          hide-details
          outlined
          :label="inputLabel"
          v-model="insertedData"
        />
      </div>
      <v-btn class="white--text primary" style="height: 40px" @click="save()">
        Добавить
      </v-btn>
    </div>
    <modal :dialog="toggle" :width="toggle.width">
      <v-form class="form" ref="form">
        <p class="subtitle mb-1">{{ inputLabel }}:</p>
        <v-text-field
          hide-details
          outlined
          v-model="editedItem.name"
          type="text"
          :required="true"
          :rules="[val => !!val.trim() || '']"
          :label="'Введите ' + inputLabel.toLowerCase()"
        ></v-text-field>
        <div class="d-flex justify-space-between mt-7">
          <v-btn
            class="white--text primary flex-grow-1 mr-5"
            @click="cancelEdit"
          >
            Отменить
          </v-btn>
          <v-btn
            class="white--text primary flex-grow-1"
            @click="$refs.form.validate() && saveEdit()"
          >
            Сохранить
          </v-btn>
        </div>
      </v-form>
    </modal>
  </div>
</template>

<script>
import { ref, watch } from "@vue/composition-api";
import { useModalToggle } from "@/use/helpers";

export default {
  props: {
    data: Array,
    inputLabel: {
      type: String,
      default: "Элемент",
    },
    tab: String,
    minHeight: {
      type: Number,
      default: 265,
    },
  },
  setup(_, { emit, root }) {
    const store = root.$store;
    const { toggle, switchToggle } = useModalToggle();

    const confirmDialog = ref(false);

    const selectedId = ref(-1);

    const insertedData = ref("");
    watch(
      () => _.tab,
      () => {
        insertedData.value = "";
      }
    );

    const editedItem = ref({});

    const editItem = id => {
      editedItem.value = { ..._.data.find(el => el.id == id) };
      switchToggle(true);
    };

    const removeItem = id => store.dispatch(`remove${_.tab.slice(0, -1)}`, id);

    const save = () => {
      if (insertedData.value.trim()) {
        emit("save", insertedData.value.trim());
        insertedData.value = "";
      }
    };

    const cancelEdit = () => {
      switchToggle(false);
      editedItem.value = {};
    };

    const saveEdit = () =>
      store
        .dispatch(`update${_.tab.slice(0, -1)}`, editedItem.value)
        .then(() => switchToggle(false));

    return {
      confirmDialog,
      selectedId,
      toggle,
      switchToggle,
      editItem,
      removeItem,
      insertedData,
      save,
      editedItem,
      cancelEdit,
      saveEdit,
    };
  },
};
</script>
