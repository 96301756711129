var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4 mb-4",attrs:{"elevation":"3"}},[(_vm.hasEmptyField)?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" Все поля должны быть заполнены ")]):_vm._e(),(_vm.ratingScale.technicalScoreFrom >= _vm.ratingScale.technicalScoreTo)?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" В шкале технических баллов начальное значение превышает или равно конечному ")]):_vm._e(),(_vm.ratingScale.totalScoreFrom >= _vm.ratingScale.totalScoreTo)?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" В шкале итоговых баллов начальное значение превышает или равно конечному ")]):_vm._e(),(
        _vm.ratingScale.technicalStep >
        _vm.ratingScale.technicalScoreTo - _vm.ratingScale.technicalScoreFrom
      )?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" Шаг шкалы технических превышает разницу начального и конечного значения ")]):_vm._e(),(
        _vm.ratingScale.exceedsExpectationsFrom >=
        _vm.ratingScale.exceedsExpectationsTo
      )?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" Превосходит ожидания ОТ превышает или равно ДО ")]):_vm._e(),(
        _vm.ratingScale.meetExpectationsFrom >= _vm.ratingScale.meetExpectationsTo
      )?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" Соответствует ожиданием ОТ превышает или равно ДО\" ")]):_vm._e(),(
        _vm.ratingScale.doNotMeetExpectationsFrom >=
        _vm.ratingScale.doNotMeetExpectationsTo
      )?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" Не соответствует ожиданием ОТ превышает или равно ДО ")]):_vm._e(),(
        _vm.ratingScale.totalScoreFrom != _vm.ratingScale.doNotMeetExpectationsFrom
      )?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" ОТ итогового значения должно быть равно Не соответствует ожиданием ОТ ")]):_vm._e(),(_vm.ratingScale.totalScoreTo != _vm.ratingScale.exceedsExpectationsTo)?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" ДО итогового значения должно быть равно Превосходит ожидания ДО ")]):_vm._e(),(
        _vm.ratingScale.doNotMeetExpectationsTo + 1 !=
        _vm.ratingScale.meetExpectationsFrom
      )?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" Нижняя граница Соответствует ожиданиям должна быть равна Верхней границе Не соответствует ожиданиям + 1 ")]):_vm._e(),(
        _vm.ratingScale.meetExpectationsTo + 1 !=
        _vm.ratingScale.exceedsExpectationsFrom
      )?_c('p',{staticClass:"text-decoration-underline font-weight-bold",style:({ color: 'red' })},[_vm._v(" Нижняя граница Соответствует ожиданиям должна быть равна Верхней границе Не соответствует ожиданиям + 1 ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }