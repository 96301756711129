var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"mt-5"},[_vm._v("Выберите профиль для оценки:")]),_c('v-row',[_c('v-col',[_c('div',[_c('v-list',{staticClass:"mx-auto py-0",staticStyle:{"max-height":"450px","overflow-y":"auto"},style:({
            maxHeight: '320px',
            overflowY: 'auto',
          }),attrs:{"elevation":"2","dense":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.profiles),function(item){return _c('v-list-item',{key:item.id,staticClass:"brd-b"},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"max-width":"100%"}},[_c('p',{staticStyle:{"max-width":"calc(100% - 80px)"}},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"flex-shrink-0"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"#767676"},on:{"click":function($event){$event.stopPropagation();return _vm.copyProfile(item.id)}}},[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"#767676"},on:{"click":function($event){$event.stopPropagation();return _vm.editProfile(item.id)}}},[_vm._v("mdi-pencil")])],1),_c('confirm',{attrs:{"icon":"","text":"Удалить профиль?"},on:{"confirm":function($event){return _vm.removeProfile(item.id)}}},[_c('v-icon',{staticStyle:{"font-size":"16px"},attrs:{"color":"#767676"}},[_vm._v("mdi-delete")])],1)],1)])])],1)}),1)],1)],1)]),_c('div',{style:({
        width: '10px',
      })}),_c('v-col',[(_vm.profiles.length)?_c('v-card',{staticClass:"py-3 h-100"},[_c('div',{staticClass:"px-3",style:({
            maxHeight: '296px',
            overflowY: 'auto',
          })},[_vm._v(" "+_vm._s((_vm.profiles[_vm.selectedItem] && _vm.profiles[_vm.selectedItem].description) || "Нет описания")+" ")])]):_vm._e()],1)],1),_c('v-row',{staticClass:"mt-n4"},[_c('v-col',[_c('div',[_c('text-btn',{attrs:{"icon":"mdi-plus"},on:{"click":_vm.addProfile}},[_vm._v("Добавить профиль")])],1)]),_c('div',{style:({
        width: '10px',
      })}),_c('v-col')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }