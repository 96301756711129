<template>
  <v-form class="mx-auto" ref="form">
    <div class="mb-2">
      <p class="subtitle mb-2">Напишите вопрос:</p>
      <v-textarea
        hide-details
        outlined
        dense
        :no-resize="true"
        label="Введите текст"
        :rules="[val => !!val || '']"
        v-model="editedQuestion.question"
      ></v-textarea>
    </div>
    <p class="subtitle mb-2">Выберите тип ответа:</p>
    <v-radio-group class="mt-0" mandatory v-model="editedQuestion.type">
      <v-radio hide-details class="mt-0" value="OPEN">
        <template v-slot:label>
          <div style="font-size: 15px; color: #000">Открытый ответ</div>
        </template>
      </v-radio>
      <v-radio hide-details class="mt-0" value="YES_NO">
        <template v-slot:label>
          <div style="font-size: 15px; color: #000">
            Да, Нет, Затрудняюсь ответить
          </div>
        </template>
      </v-radio>
      <v-radio hide-details class="mt-0" value="SCALE">
        <template v-slot:label>
          <div style="font-size: 15px; color: #000">Выбор балла от 1 до 10</div>
        </template></v-radio
      >
    </v-radio-group>
    <div class="d-flex justify-space-between">
      <v-btn class="white--text primary flex-grow-1 mr-5" @click="cancel">
        Отменить
      </v-btn>
      <v-btn
        class="white--text primary flex-grow-1"
        @click="$refs.form.validate() && isChanged && save()"
      >
        Сохранить
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: {
    questionData: {
      type: Object,
      default: () => ({
        type: "OPEN",
        question: "",
        indexNumber: 0,
      }),
    },
    type: {
      type: String,
    },
  },
  setup(_, { emit, refs }) {
    const isChanged = ref(false);
    const editedQuestion = ref({ ..._.questionData });

    watch(editedQuestion.value, () => {
      isChanged.value = true;
    });

    const cancel = () => {
      if (!isChanged.value) {
        emit("close");
      } else {
        isChanged.value = false;
        editedQuestion.value = { ..._.questionData };
        watch(editedQuestion.value, () => {
          isChanged.value = true;
        });
      }
    };

    const save = async () => {
      if (!refs.form.validate()) return;

      emit("question-saved", editedQuestion.value, _.type);
      emit("close");
    };

    return {
      save,
      isChanged,
      editedQuestion,
      cancel,
    };
  },
};
</script>
