<template>
  <div>
    <v-card class="pa-4 mb-4" elevation="3">
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="hasEmptyField"
        :style="{ color: 'red' }"
      >
        Все поля должны быть заполнены
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="ratingScale.technicalScoreFrom >= ratingScale.technicalScoreTo"
        :style="{ color: 'red' }"
      >
        В шкале технических баллов начальное значение превышает или равно
        конечному
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="ratingScale.totalScoreFrom >= ratingScale.totalScoreTo"
        :style="{ color: 'red' }"
      >
        В шкале итоговых баллов начальное значение превышает или равно конечному
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="
          ratingScale.technicalStep >
          ratingScale.technicalScoreTo - ratingScale.technicalScoreFrom
        "
        :style="{ color: 'red' }"
      >
        Шаг шкалы технических превышает разницу начального и конечного значения
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="
          ratingScale.exceedsExpectationsFrom >=
          ratingScale.exceedsExpectationsTo
        "
        :style="{ color: 'red' }"
      >
        Превосходит ожидания ОТ превышает или равно ДО
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="
          ratingScale.meetExpectationsFrom >= ratingScale.meetExpectationsTo
        "
        :style="{ color: 'red' }"
      >
        Соответствует ожиданием ОТ превышает или равно ДО"
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="
          ratingScale.doNotMeetExpectationsFrom >=
          ratingScale.doNotMeetExpectationsTo
        "
        :style="{ color: 'red' }"
      >
        Не соответствует ожиданием ОТ превышает или равно ДО
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="
          ratingScale.totalScoreFrom != ratingScale.doNotMeetExpectationsFrom
        "
        :style="{ color: 'red' }"
      >
        ОТ итогового значения должно быть равно Не соответствует ожиданием ОТ
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="ratingScale.totalScoreTo != ratingScale.exceedsExpectationsTo"
        :style="{ color: 'red' }"
      >
        ДО итогового значения должно быть равно Превосходит ожидания ДО
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="
          ratingScale.doNotMeetExpectationsTo + 1 !=
          ratingScale.meetExpectationsFrom
        "
        :style="{ color: 'red' }"
      >
        Нижняя граница Соответствует ожиданиям должна быть равна Верхней границе
        Не соответствует ожиданиям + 1
      </p>
      <p
        class="text-decoration-underline font-weight-bold"
        v-if="
          ratingScale.meetExpectationsTo + 1 !=
          ratingScale.exceedsExpectationsFrom
        "
        :style="{ color: 'red' }"
      >
        Нижняя граница Соответствует ожиданиям должна быть равна Верхней границе
        Не соответствует ожиданиям + 1
      </p>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["ratingScale"],
  computed: {
    hasEmptyField() {
      return Object.values(this.ratingScale).filter(r => r === "").length;
    },
  },
};
</script>
