<template>
  <div>
    <div v-if="selectedSubtab">
      <component
        :is="selectedSubtab.name"
        :task="editedTask"
        :data="selectedSubtab.selected"
        @back="selectedSubtab = null"
        @instruction-saved="updateInstruction"
      ></component>
    </div>
    <div v-else>
      <div class="d-flex justify-space-between">
        <text-btn small icon="mdi-arrow-left" @click="$emit('back')"
          >К списку заданий</text-btn
        >
      </div>
      <v-form class="form" ref="form">
        <div class="mb-3">
          <p class="subtitle primary--text mb-1">Название:</p>
          <v-text-field
            hide-details
            outlined
            v-model="editedTask.name"
            type="text"
            :required="true"
            :rules="[val => !!val || '']"
            label="Введите название задания"
          ></v-text-field>
        </div>

        <div class="mb-3">
          <p class="subtitle primary--text mb-1">Краткое содержание:</p>
          <v-textarea
            hide-details
            outlined
            no-resize
            v-model="editedTask.description"
            type="text"
            :required="true"
            :rules="[val => !!val || '']"
            label="Введите краткое содержание задания"
          ></v-textarea>
        </div>

        <div class="mb-3">
          <p class="subtitle primary--text mb-1">Письма:</p>
          <v-select
            hide-details
            dense
            outlined
            v-model="editedTask.emailIdList"
            item-text="title"
            item-value="id"
            :items="simulation.emails"
            label="Выбрать письма"
            multiple
            chips
            class="chip-wrap"
          >
            <template v-slot:selection="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
        <div class="mb-3">
          <p class="subtitle primary--text mb-1">Выводить ответ на письмо:</p>
          <v-select
            hide-details
            dense
            outlined
            v-model="editedTask.generalEmailId"
            item-text="title"
            item-value="id"
            :items="simulation.emails"
            label="Выберите письмо, ответ на которое будет оценен в задании"
          />
        </div>
        <div class="mb-3">
          <p class="subtitle primary--text mb-1">Встречи:</p>
          <v-select
            hide-details
            dense
            outlined
            v-model="editedTask.meetingIdList"
            item-text="title"
            item-value="id"
            :items="simulation.events"
            label="Выбрать встречи"
            multiple
            chips
            validate-on-blur
            class="chip-wrap"
          >
            <template v-slot:selection="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
        <div class="mb-3">
          <p class="subtitle primary--text mb-1">Инструкции для эксперта:</p>
          <v-card
            v-if="editedTask.expertInstructions"
            class="form mb-1"
            elevation="3"
            style="max-height: 300px; overflow: auto"
          >
            <v-list dense class="py-0">
              <v-list-item
                class="brd-b"
                v-for="(item, idx) in editedTask.expertInstructions"
                :key="idx"
              >
                <v-list-item-content>
                  <div
                    class="d-flex justify-space-between align-center"
                    style="width: 100%"
                  >
                    <p style="max-width: calc(100% - 80px)">{{ item.name }}</p>

                    <div class="flex-shrink-0">
                      <v-btn
                        icon
                        @click.stop="
                          editInstruction(idx),
                            (selectedSubtab = subtabs.find(
                              el => el.name == 'instructions'
                            ))
                        "
                      >
                        <v-icon color="#767676" style="font-size: 16px"
                          >mdi-pencil</v-icon
                        >
                      </v-btn>
                      <v-btn icon @click.stop="removeInstruction(idx)">
                        <v-icon color="#767676" style="font-size: 16px"
                          >mdi-delete</v-icon
                        >
                      </v-btn>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <text-btn
            icon="mdi-plus"
            @click="
              selectedSubtab = subtabs.find(el => el.name == 'instructions')
            "
            >Добавить инструкцию</text-btn
          >
        </div>
        <div>
          <p class="subtitle primary--text mb-1">
            Выбрано индикаторов ({{ editedTask.indicators.length }})
          </p>
          <text-btn
            right
            icon="mdi-arrow-right"
            @click="
              selectedSubtab = subtabs.find(el => el.name == 'indicators')
            "
            >{{
              editedTask.indicators.length
                ? "Изменить выбор индикаторов"
                : "Выбрать индикаторы"
            }}</text-btn
          >
        </div>
        <div class="d-flex justify-space-between mt-7">
          <confirm
            :extDialog="isResetConfirmShown"
            @confirm="reset"
            @cancel="isResetConfirmShown = false"
            text="Отменить изменения?"
          >
            <v-btn
              class="white--text primary flex-grow-1 mr-5"
              @click="
                isNeedConfirm ? (isResetConfirmShown = true) : showWarning()
              "
            >
              Отменить
            </v-btn>
          </confirm>
          <confirm
            :extDialog="isSaveConfirmShown"
            @confirm="save"
            @cancel="isSaveConfirmShown = false"
            text="Вы действительно хотите сохранить изменения?"
          >
            <v-btn
              class="white--text primary flex-grow-1"
              @click="
                $refs.form.validate() && isNeedConfirm
                  ? (isSaveConfirmShown = true)
                  : showWarning()
              "
            >
              Сохранить
            </v-btn>
          </confirm>
        </div>
      </v-form>
    </div>

    <preloader v-if="loading" />
  </div>
</template>

<script>
import { ref, computed, inject, watch } from "@vue/composition-api";
import Indicators from "./Indicators";
import Instructions from "./Instructions.vue";

export default {
  props: {
    task: Object,
    profileId: String,
  },
  setup(_, { emit, root }) {
    const store = root.$store;

    const loading = inject("loading");

    const panelsIndexes = ref([...Array(100).keys()]);

    const simulation = computed(() => store.getters.getProfileSimulation);

    const subtabs = ref([
      {
        name: "indicators",
        label: "Индикаторы",
      },
      {
        name: "instructions",
        label: "Инструкции эксперта",
        selected: null,
      },
    ]);

    const selectedSubtab = ref(null);

    const editedTask = ref(JSON.parse(JSON.stringify(_.task)));

    const initialTask = ref(JSON.parse(JSON.stringify(editedTask.value)));

    const editedInstruction = ref({});
    const initialInstruction = ref({});

    const save = async (withTabSwitch = false) => {
      loading.value = true;
      if (withTabSwitch) editedTask.value.withTabSwitch = true;
      await store
        .dispatch("createOrUpdateProfileTask", {
          profileId: _.profileId,
          task: editedTask.value,
        })
        .then(() => (isNeedConfirm.value = false));
      loading.value = false;
      emit("back");
    };

    const editInstruction = index => {
      editedInstruction.value = editedTask.value.expertInstructions.find(
        (el, idx) => idx == index
      );
      initialInstruction.value = JSON.parse(
        JSON.stringify(editedInstruction.value)
      );

      subtabs.value.find(el => el.name == "instructions").selected =
        editedInstruction.value;
    };

    const removeInstruction = index => {
      editedTask.value.expertInstructions.splice(index, 1);
      editedInstruction.value = {};
    };
    const updateInstruction = ({ data, isEdit }) => {
      if (isEdit) {
        const index = editedTask.value.expertInstructions.findIndex(
          el => el == editedInstruction.value
        );

        editedTask.value.expertInstructions.splice(index, 1, data);
      } else {
        editedTask.value.expertInstructions.push(data);
      }

      editedInstruction.value = {};
      subtabs.value.find(el => el.name == "instructions").selected = null;
    };

    const reset = () => {
      editedTask.value = JSON.parse(JSON.stringify(_.task));

      emit("back");
    };

    const isResetConfirmShown = ref(false);
    const isSaveConfirmShown = ref(false);

    const isNeedConfirm = inject("isNeedConfirm");
    const saveFunction = inject("saveFunction");
    saveFunction.value = save;
    const showWarning = inject("showWarning");

    watch(
      () => editedTask.value,
      val => {
        if (JSON.stringify(val) !== JSON.stringify(initialTask.value))
          isNeedConfirm.value = true;
      },
      { deep: true }
    );
    watch(
      () => editedInstruction.value,
      val => {
        if (JSON.stringify(val) !== JSON.stringify(initialInstruction.value))
          isNeedConfirm.value = true;
      },
      { deep: true }
    );

    return {
      loading,
      panelsIndexes,
      simulation,
      subtabs,
      selectedSubtab,
      editedTask,
      editedInstruction,
      editInstruction,
      removeInstruction,
      updateInstruction,
      isResetConfirmShown,
      isSaveConfirmShown,
      reset,
      save,
      isNeedConfirm,
      showWarning,
    };
  },
  components: {
    Indicators,
    Instructions,
  },
};
</script>
