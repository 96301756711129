<template>
  <div class="mb-3">
    <p class="subtitle mb-1 primary--text">Модель компетенций:</p>
    <v-card
      v-if="competences.length"
      class="mb-2 panels"
      style="max-width: 570px; max-height: 400px; overflow: auto"
      elevation="3"
    >
      <expansion-list :items="competences">
        <template v-slot:header="{ item }">
          <div class="d-flex justify-space-between align-center">
            <span class="d-flex flex-grow-1" style="line-height: 1.5rem">
              {{ item.indexNumber }}. {{ item.name }}
            </span>
            <div class="flex-shrink-0">
              <v-btn icon @click.stop="editCompetence(item.id)">
                <v-icon small style="color: #767676">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                @click.stop="
                  (selectedId = item.id), (isRemoveConfirmShown = true)
                "
              >
                <v-icon small style="color: #767676">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:content="{ item }">
          <v-list class="py-0" disabled>
            <v-list-item
              v-for="indicator in item.indicators"
              :key="indicator.id"
              class="indicator--active white"
            >
              <div class="d-flex">
                <div>
                  {{ item.indexNumber }}.{{ indicator.indexNumber }}
                  {{ indicator.name }}
                </div>
              </div>
            </v-list-item>
          </v-list>
        </template>
      </expansion-list>
      <confirm
        :extDialog="isRemoveConfirmShown"
        text="При удалении компетенции или индикатора они также удалятся в заданиях для оценки. Продолжить?"
        @cancel="isRemoveConfirmShown = false"
        @confirm="removeCompetence"
      >
      </confirm>
    </v-card>
    <text-btn icon="mdi-plus" @click="switchToggle(true, 'create')"
      >Добавить компетенцию</text-btn
    >

    <modal :dialog="toggle" :width="800" :persistent="true">
      <CompetenceForm
        v-if="toggle.view == 'create'"
        @competence-saved="updateCompetence"
        :index="competences.length + 1"
        @close="switchToggle(false, 'create')"
      />
      <CompetenceForm
        v-else-if="toggle.view == 'edit'"
        :editedCompetence="editedCompetence"
        @competence-saved="updateCompetence"
        @close="switchToggle(false, 'edit')"
      />
    </modal>
  </div>
</template>

<script>
import CompetenceForm from "./CompetenceForm";
import { useModalToggle } from "@/use/helpers";
import { ref, computed } from "@vue/composition-api";

export default {
  props: {
    profileId: {
      type: String,
    },
  },
  setup(_, { root }) {
    const store = root.$store;
    const { toggle, switchToggle } = useModalToggle();

    const competences = computed(
      () => store.getters.getCurrentProfile.competences
    );

    const isRemoveConfirmShown = ref(false);

    const selectedId = ref(false);

    const editedCompetence = ref({});
    const competenceIndex = ref(0);

    const editCompetence = (id, index) => {
      editedCompetence.value = competences.value.find(el => el.id == id);
      competenceIndex.value = index;
      switchToggle(true, "edit");
    };

    const removeCompetence = () => {
      store.dispatch("removeCompetence", {
        profileId: _.profileId,
        competenceId: selectedId.value,
      });
      isRemoveConfirmShown.value = false;
    };

    const updateCompetence = data =>
      store.dispatch("updateCompetence", {
        profileId: _.profileId,
        competence: data,
      });

    return {
      toggle,
      competences,
      isRemoveConfirmShown,
      selectedId,
      switchToggle,
      editCompetence,
      editedCompetence,
      removeCompetence,
      competenceIndex,
      updateCompetence,
    };
  },
  components: {
    CompetenceForm,
  },
};
</script>
