<template>
  <div>
    <Competences :competences="profile.competences" :profileId="profile.id" />
    <Scores :options="profile.ratingScale" :profileId="profile.id" />
  </div>
</template>

<script>
import Competences from "./competences/Competences";
import Scores from "./competences/scores/Scores.vue";

export default {
  props: ["profile"],
  setup() {},
  components: {
    Competences,
    Scores,
  },
};
</script>
