<template>
  <div>
    <TaskList v-if="!selectedTask" :tasks="tasks" @task-selected="selectTask" />
    <TaskItem
      v-else
      :task="selectedTask"
      :profileId="profile.id"
      @back="(selectedTask = null), $emit('subtab-left')"
    />
  </div>
</template>

<script>
import TaskList from "./tasks/TaskList";
import TaskItem from "./tasks/TaskItem";
import {
  ref,
  computed,
  onBeforeMount,
  onBeforeUnmount,
} from "@vue/composition-api";

export default {
  props: ["profile"],
  setup(_, { emit, root }) {
    const store = root.$store;

    onBeforeMount(async () => {
      await store.dispatch("fetchProfileTasks", _.profile.id);
    });

    onBeforeUnmount(() => {
      store.commit("clearProfileTasks");
    });

    const tasks = computed(() => store.getters.getProfileTasks);

    const selectedTask = ref(null);

    const selectTask = async item => {
      if (item.id) {
        await store.dispatch("fetchCurrentProfileTask", item.id);
        selectedTask.value = store.getters.getCurrentProfileTask;
      } else {
        selectedTask.value = {
          name: "",
          emailIdList: [],
          generalEmailId: "",
          meetingIdList: [],
          expertInstructions: [],
          indicators: [],
        };
      }

      emit("subtab-entered");
    };

    return {
      tasks,
      selectedTask,
      selectTask,
    };
  },
  components: {
    TaskList,
    TaskItem,
  },
};
</script>
