<template>
  <div>
    <div class="d-flex justify-space-between">
      <text-btn small icon="mdi-arrow-left" @click="$emit('back')"
        >Вернуться к заданию</text-btn
      >
    </div>
    <v-container class="pa-0">
      <v-row>
        <v-col sm="6" cols="12">
          <v-card
            elevation="4"
            height="70vh"
            class="panels"
            style="overflow-y: auto"
          >
            <v-expansion-panels
              v-if="profile.competences.length && profile.competences[0].id"
              accordion
              elevation="0"
              multiple
              v-model="panelsIndexes"
            >
              <v-list-item-group>
                <v-expansion-panel
                  v-for="(item, i) in profile.competences"
                  :key="i"
                >
                  <v-expansion-panel-header
                    >{{ item.indexNumber }}.
                    {{ item.name }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-list class="py-0">
                      <v-list-item
                        v-for="(indicator, idx) in item.indicators"
                        :key="indicator.indexNumber"
                        :class="{
                          'indicator--active': selectedIndicator == indicator,
                        }"
                        @click="selectIndicator(indicator, item)"
                      >
                        <div class="d-flex" style="width: 100%">
                          <div
                            class="ml-n4"
                            style="position: relative; width: 40px"
                          >
                            <v-checkbox
                              :key="idx"
                              class="pa-0 ma-0"
                              :ripple="false"
                              v-model="indicator.selected"
                              hide-details
                            ></v-checkbox>
                          </div>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <div class="d-flex">
                              <div class="pr-1 flex-shrink-0">
                                {{ item.indexNumber }}.
                                {{ indicator.indexNumber }}
                              </div>
                              <div>{{ indicator.name }}</div>
                            </div>
                            <div v-if="indicator.selected">
                              <v-btn icon @click="editIndicator(indicator)">
                                <v-icon color="#767676" style="font-size: 16px"
                                  >mdi-pencil</v-icon
                                ></v-btn
                              >
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-list-item-group>
            </v-expansion-panels>
          </v-card>
        </v-col>
        <v-col sm="6" cols="12">
          <v-card
            class="pa-3"
            elevation="4"
            height="70vh"
            style="overflow-y: auto"
            color="#efefef"
          >
            <div>
              <p
                v-for="(item, idx) in selectedIndicator.levelMarks"
                :key="idx"
                class="mb-4"
              >
                <span
                  class="subtitle primary--text d-inline-block text-center"
                  style="min-width: 9px"
                  >{{ item.level }}</span
                >
                -
                {{ item.description }}
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mx-auto mt-3" style="max-width: 400px">
        <confirm
          :extDialog="isResetConfirmShown"
          @confirm="$emit('back')"
          @cancel="isResetConfirmShown = false"
          text="Вы действительно хотите отменить изменения?"
        >
          <v-btn
            class="white--text primary flex-grow-1 mr-5"
            @click="isResetConfirmShown = true"
            min-width="210px"
          >
            Отмена
          </v-btn>
        </confirm>

        <v-btn
          class="white--text primary flex-grow-1"
          @click="save"
          min-width="210px"
        >
          Сохранить
        </v-btn>
      </div>
    </v-container>
    <modal :dialog="toggle" :width="800" :persistent="true">
      <IndicatorsForm
        v-if="toggle.view"
        :indicator="editedIndicator"
        :competence="selectedCompetence"
        @save="updateIndicator"
        @close="switchToggle(false)"
      />
    </modal>

    <preloader v-if="loading" />
  </div>
</template>

<script>
import useIndicators from "@/use/indicators";
import { useModalToggle } from "@/use/helpers";
import { ref, inject } from "@vue/composition-api";
import IndicatorsForm from "./IndicatorsForm";

export default {
  props: ["task"],
  setup(_, { root, emit }) {
    const store = root.$store;

    const loading = inject("loading");

    const { toggle, switchToggle } = useModalToggle();
    const panelsIndexes = ref([...Array(100).keys()]);

    const profile = ref(
      (() => {
        let tmpProfile = JSON.parse(
          JSON.stringify(store.getters.getCurrentProfile)
        );

        _.task.indicators.forEach(el => {
          const indicator = tmpProfile.competences
            .find(comp => comp.id == el.competenceId)
            .indicators.find(indi => indi.id == el.id);

          Object.keys(el).forEach(key => (indicator[key] = el[key]));
          indicator.selected = ref(true);
        });

        tmpProfile.competences.forEach(competence => {
          competence.indicators.forEach(indicator => {
            if (!indicator.weight) indicator.weight = 1;
            if (!indicator.levelMarks?.length) {
              indicator.levelMarks = [];
              const ratingScale = store.getters.getCurrentProfile.ratingScale;
              for (
                let i = ratingScale.technicalScoreFrom;
                i <= ratingScale.technicalScoreTo;
                i += ratingScale.technicalStep
              )
                indicator.levelMarks.push({
                  description: "",
                  level: i,
                });
              if (ratingScale.useNoData)
                indicator.levelMarks.push({
                  description: "",
                  level: "Нет оценки",
                });
            }
          });
        });

        return tmpProfile;
      })()
    );

    const { selectedIndicator, selectedCompetence, selectIndicator } =
      useIndicators(profile.value.competences);

    const editedIndicator = ref({});
    const editIndicator = item => {
      editedIndicator.value = item;
      switchToggle(true, "edit");
    };
    const updateIndicator = ({ competence, indicator }) => {
      const indicatorIndex = competence.indicators.findIndex(
        el => el.id == indicator.id
      );
      competence.indicators.splice(indicatorIndex, 1, indicator);
      editedIndicator.value = {};

      selectedIndicator.value = indicator;

      save(false);
    };

    const save = async (isNeedReturn = true) => {
      loading.value = true;
      const competences = JSON.parse(JSON.stringify(profile.value.competences));
      const selectedCompetences = competences
        .map(competence => {
          competence.indicators = competence.indicators.filter(
            indicator => indicator.selected
          );
          return competence;
        })
        .filter(competence => competence.indicators.length);

      const indicators = selectedCompetences.reduce((acc, el) => {
        return acc.concat(
          ...el.indicators.map(indicator => {
            return {
              id: indicator.id,
              competenceId: el.id,
              weight: indicator.weight,
              levelMarks: indicator.levelMarks,
            };
          })
        );
      }, []);

      _.task.indicators = indicators;

      await store
        .dispatch("createOrUpdateProfileTask", {
          profileId: profile.value.id,
          task: _.task,
          successText: "Индикатор сохранен",
        })
        .then(() => {
          if (isNeedReturn) emit("back");
        });
      loading.value = false;
    };

    const isResetConfirmShown = ref(false);

    return {
      loading,
      toggle,
      switchToggle,
      panelsIndexes,
      profile,
      selectedIndicator,
      selectedCompetence,
      selectIndicator,
      editedIndicator,
      updateIndicator,
      editIndicator,
      save,
      isResetConfirmShown,
    };
  },
  components: {
    IndicatorsForm,
  },
};
</script>
