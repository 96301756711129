<template>
  <div>
    <v-form class="form" ref="form">
      <p class="subtitle primary--text text-center mb-4">Добавьте документ</p>
      <div class="mb-3">
        <p class="subtitle mb-1">Название:</p>
        <v-text-field
          hide-details
          outlined
          v-model="doc.name"
          type="text"
          :required="true"
          :rules="[val => !!val || '']"
          label="Введите название документы"
        ></v-text-field>
      </div>
      <div class="mb-3">
        <p class="subtitle mb-1">Ссылка:</p>
        <v-text-field
          hide-details
          outlined
          v-model="doc.link"
          type="text"
          :required="true"
          :rules="[val => !!val || '']"
          label="Вставьте ссылку на документ"
        ></v-text-field>
      </div>

      <div class="d-flex justify-space-between mt-7">
        <v-btn class="white--text primary flex-grow-1 mr-5" @click="cancel">
          Отменить
        </v-btn>
        <v-btn
          class="white--text primary flex-grow-1"
          @click="$refs.form.validate() && save()"
        >
          Добавить
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  props: {
    editedDoc: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { emit }) {
    const doc = ref({ ..._.editedDoc });

    const cancel = () => {
      emit("close");
    };

    const save = () => {
      emit("save", doc.value);
      cancel();
    };

    return {
      doc,
      cancel,
      save,
    };
  },
};
</script>
