<template>
  <div>
    <h3 class="mt-5">Выберите профиль для оценки:</h3>
    <v-row>
      <v-col>
        <div>
          <v-list
            :style="{
              maxHeight: '320px',
              overflowY: 'auto',
            }"
            elevation="2"
            dense
            class="mx-auto py-0"
            style="max-height: 450px; overflow-y: auto"
          >
            <v-list-item-group v-model="selectedItem">
              <v-list-item
                class="brd-b"
                v-for="item in profiles"
                :key="item.id"
              >
                <v-list-item-content>
                  <div
                    style="max-width: 100%"
                    class="d-flex justify-space-between align-center"
                  >
                    <p style="max-width: calc(100% - 80px)">{{ item.name }}</p>
                    <div class="flex-shrink-0">
                      <v-btn icon>
                        <v-icon
                          color="#767676"
                          style="font-size: 16px"
                          @click.stop="copyProfile(item.id)"
                          >mdi-content-copy</v-icon
                        >
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          color="#767676"
                          style="font-size: 16px"
                          @click.stop="editProfile(item.id)"
                          >mdi-pencil</v-icon
                        >
                      </v-btn>
                      <confirm
                        icon
                        text="Удалить профиль?"
                        @confirm="removeProfile(item.id)"
                      >
                        <v-icon color="#767676" style="font-size: 16px"
                          >mdi-delete</v-icon
                        >
                      </confirm>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
      <div
        :style="{
          width: '10px',
        }"
      ></div>
      <v-col>
        <v-card v-if="profiles.length" class="py-3 h-100">
          <div
            class="px-3"
            :style="{
              maxHeight: '296px',
              overflowY: 'auto',
            }"
          >
            {{
              (profiles[selectedItem] && profiles[selectedItem].description) ||
              "Нет описания"
            }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-n4">
      <v-col>
        <div>
          <text-btn icon="mdi-plus" @click="addProfile"
            >Добавить профиль</text-btn
          >
        </div>
      </v-col>
      <div
        :style="{
          width: '10px',
        }"
      ></div>
      <v-col></v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, inject } from "@vue/composition-api";

export default {
  props: ["profiles"],
  setup(_, { emit, root }) {
    const store = root.$store;

    const loading = inject("loading");

    const profile = computed(() => store.getters.getCurrentProfile);

    const selectedItem = ref(0);

    const editProfile = async id => {
      loading.value = true;
      await fetchProfile(id);
      emit("profile-selected", profile.value);
      loading.value = false;
    };

    const fetchProfile = async id => {
      await store.dispatch("fetchProfileById", id);
      await store.dispatch("fetchProfileSimualtion", id);
    };

    const removeProfile = async id => {
      store.dispatch("removeProfile", id);
      selectedItem.value = {};
    };

    const addProfile = () => {
      emit("profile-added");
    };

    const copyProfile = async id => {
      loading.value = true;
      await store.dispatch("copyProfile", id);
      loading.value = false;
    };

    return {
      selectedItem,
      addProfile,
      editProfile,
      removeProfile,
      copyProfile,
    };
  },
};
</script>
