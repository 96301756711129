<template>
  <div>
    <p class="subtitle primary--text">Шкала оценки:</p>
    <v-form ref="form" class="mt-3">
      <div class="d-flex flex-grow-1">
        <v-card class="pa-4 felx-grow-1 mr-3" elevation="3">
          <p class="subtitle primary--text text-center">Технические баллы:</p>
          <div>
            <p class="mt-3">
              Шкала технических баллов от
              <v-text-field
                outlined
                dense
                class="field--inline"
                type="text"
                :rules="[rules.technicalScoreFrom]"
                v-model.number="ratingScale.technicalScoreFrom"
                @keydown="fieldScoreValidate"
              />
              до
              <v-text-field
                outlined
                dense
                class="field--inline"
                type="text"
                required
                :rules="[rules.technicalScoreTo]"
                v-model.number="ratingScale.technicalScoreTo"
                @keydown="fieldScoreValidate"
              />
              с шагом
              <v-text-field
                outlined
                dense
                hide-details
                class="field--inline"
                type="text"
                required
                :rules="[rules.technicalStep]"
                v-model="ratingScale.technicalStep"
                @keydown="fieldScoreValidate"
              ></v-text-field>
            </p>
            <p class="mb-5" style="position: relative; margin-right: 60px">
              При расчете среднего округляем до
              <v-radio-group
                class="field--inline"
                v-model="ratingScale.withRoundingToInt"
              >
                <v-radio label="1/10" :value="false" />
                <v-radio label="целого" :value="true" />
              </v-radio-group>
            </p>
            <p class="mt-5" style="position: relative; margin-right: 60px">
              Использовать &laquo;нет данных&raquo;
              <v-checkbox
                class="field--inline"
                style="position: absolute; bottom: -1px"
                v-model="ratingScale.useNoData"
                :label="ratingScale.useNoData ? 'да' : 'нет'"
              ></v-checkbox>
            </p>
          </div>
        </v-card>
        <v-card class="pa-4 flex-grow-1 ml-3" elevation="3">
          <p class="subtitle mb-1 primary--text text-center">Итоговые баллы</p>
          <div>
            <p class="mt-3">
              Шкала итоговых баллов от
              <v-text-field
                outlined
                dense
                hide-details
                class="field--inline"
                type="text"
                required
                :rules="[rules.totalScoreFrom]"
                v-model.number="ratingScale.totalScoreFrom"
                @keydown="fieldScoreValidate"
              ></v-text-field>
              до
              <v-text-field
                outlined
                dense
                hide-details
                class="field--inline"
                type="text"
                required
                :rules="[rules.totalScoreTo]"
                v-model.number="ratingScale.totalScoreTo"
                @keydown="fieldScoreValidate"
              ></v-text-field>
            </p>
          </div>
        </v-card>
      </div>
    </v-form>

    <div class="mt-5">
      <v-card class="pa-4" elevation="3">
        <p class="subtitle mb-1 primary--text text-center">
          Перевод технических баллов в итоговые
        </p>
        <ScoreSlider :options="ratingScale" />
      </v-card>
    </div>

    <Errors v-if="!isValid" :ratingScale="ratingScale" />

    <v-card class="pa-4 mt-5" elevation="3">
      <p class="subtitle mb-1 primary--text text-center">
        Выбор диапазона итоговых баллов для итогового вывода
      </p>
      <div>
        <p class="my-5">
          Превосходит ожидания от
          <v-text-field
            outlined
            dense
            hide-details
            class="field--inline"
            type="text"
            required
            :rules="[rules.exceedsExpectationsFrom]"
            v-model.number="ratingScale.exceedsExpectationsFrom"
            @keydown="fieldScoreValidate"
          ></v-text-field>
          до
          <v-text-field
            outlined
            dense
            hide-details
            class="field--inline"
            type="text"
            required
            :rules="[rules.exceedsExpectationsTo]"
            v-model.number="ratingScale.exceedsExpectationsTo"
            @keydown="fieldScoreValidate"
          ></v-text-field>
        </p>
        <p class="my-5">
          Соответствует ожиданиям от
          <v-text-field
            outlined
            dense
            hide-details
            class="field--inline"
            type="text"
            required
            :rules="[rules.meetExpectationsFrom]"
            v-model.number="ratingScale.meetExpectationsFrom"
            @keydown="fieldScoreValidate"
          ></v-text-field>
          до
          <v-text-field
            outlined
            dense
            hide-details
            class="field--inline"
            type="text"
            required
            :rules="[rules.meetExpectationsTo]"
            v-model.number="ratingScale.meetExpectationsTo"
            @keydown="fieldScoreValidate"
          ></v-text-field>
        </p>
        <p class="my-5">
          Не соответствует ожиданиям от
          <v-text-field
            outlined
            dense
            hide-details
            class="field--inline"
            type="text"
            required
            :rules="[rules.doNotMeetExpectationsFrom]"
            v-model.number="ratingScale.doNotMeetExpectationsFrom"
            @keydown="fieldScoreValidate"
          ></v-text-field>
          до
          <v-text-field
            outlined
            dense
            hide-details
            class="field--inline"
            type="text"
            required
            :rules="[rules.doNotMeetExpectationsTo]"
            v-model.number="ratingScale.doNotMeetExpectationsTo"
            @keydown="fieldScoreValidate"
          ></v-text-field>
        </p>
      </div>
    </v-card>

    <div class="d-flex justify-space-between mt-5 form">
      <confirm
        :extDialog="resetConfirm"
        @cancel="scoresConfirm = false"
        @confirm="reset"
        text="Отменить изменения?"
      >
        <v-btn
          class="white--text primary flex-grow-1 mr-5"
          @click="isNeedConfirm ? (resetConfirm = true) : showWarning()"
        >
          Отменить
        </v-btn>
      </confirm>
      <confirm
        :extDialog="scoresConfirm"
        @cancel="scoresConfirm = false"
        @confirm="save"
        text="Ключи у выбранных индикаторов в заданиях будут удалены, изменить шкалу?"
      >
        <v-btn
          class="white--text primary flex-grow-1"
          @click="
            $refs.form.validate() && isNeedConfirm
              ? (scoresConfirm = true)
              : showWarning()
          "
        >
          Сохранить
        </v-btn>
      </confirm>
    </div>
  </div>
</template>

<script>
import ScoreSlider from "./ScoreSlider.vue";
import Errors from "./Errors.vue";
import { ref, watch, inject } from "@vue/composition-api";

export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          technicalScoreFrom: 2,
          technicalScoreTo: 4,
          technicalStep: 1,
          totalScoreFrom: 0,
          totalScoreTo: 10,
          withRoundingToInt: true,
          useNoData: false,
          exceedsExpectationsTo: 10,
          exceedsExpectationsFrom: 7,
          meetExpectationsFrom: 4,
          meetExpectationsTo: 6,
          doNotMeetExpectationsFrom: 1,
          doNotMeetExpectationsTo: 3,
        };
      },
    },
    profileId: String,
  },
  setup(_, { root, refs }) {
    const store = root.$store;

    const rules = {
      technicalScoreFrom: val => {
        return (
          (!!val && val < ratingScale.value.technicalScoreTo) ||
          (val == "0" &&
            ratingScale.value.technicalScoreFrom !=
              ratingScale.value.technicalScoreTo)
        );
      },
      technicalScoreTo: val => {
        return (
          (!!val && val > ratingScale.value.technicalScoreFrom) ||
          (val == "0" &&
            ratingScale.value.technicalScoreFrom !=
              ratingScale.value.technicalScoreTo)
        );
      },
      technicalStep: val => {
        return (
          !!val &&
          val != "0" &&
          (ratingScale.value.technicalScoreTo -
            ratingScale.value.technicalScoreFrom) %
            val ==
            0
        );
      },
      totalScoreFrom: val => {
        return (
          (!!val &&
            val < ratingScale.value.totalScoreTo &&
            ratingScale.value.totalScoreFrom ===
              ratingScale.value.doNotMeetExpectationsFrom) ||
          (val == "0" &&
            ratingScale.value.totalScoreFrom != ratingScale.value.totalScoreTo)
        );
      },
      totalScoreTo: val => {
        return (
          (!!val &&
            val > ratingScale.value.totalScoreFrom &&
            ratingScale.value.totalScoreTo ===
              ratingScale.value.exceedsExpectationsTo) ||
          (val == "0" &&
            ratingScale.value.totalScoreFrom != ratingScale.value.totalScoreTo)
        );
      },
      exceedsExpectationsFrom: val => {
        return (
          (!!val && val < ratingScale.value.exceedsExpectationsTo) ||
          (val == "0" &&
            ratingScale.value.exceedsExpectationsFrom !=
              ratingScale.value.exceedsExpectationsTo)
        );
      },
      exceedsExpectationsTo: val => {
        return (
          (!!val &&
            val > ratingScale.value.exceedsExpectationsFrom &&
            ratingScale.value.totalScoreTo ===
              ratingScale.value.exceedsExpectationsTo) ||
          (val == "0" &&
            ratingScale.value.exceedsExpectationsFrom !=
              ratingScale.value.exceedsExpectationsTo)
        );
      },
      meetExpectationsFrom: val => {
        return (
          (!!val &&
            val < ratingScale.value.meetExpectationsTo &&
            ratingScale.value.meetExpectationsFrom ===
              ratingScale.value.doNotMeetExpectationsTo + 1) ||
          (val == "0" &&
            ratingScale.value.meetExpectationsTo !=
              ratingScale.value.meetExpectationsFrom)
        );
      },
      meetExpectationsTo: val => {
        return (
          (!!val &&
            val > ratingScale.value.meetExpectationsFrom &&
            ratingScale.value.meetExpectationsTo + 1 ===
              ratingScale.value.exceedsExpectationsFrom) ||
          (val == "0" &&
            ratingScale.value.meetExpectationsTo !=
              ratingScale.value.meetExpectationsFrom)
        );
      },
      doNotMeetExpectationsFrom: val => {
        return (
          (!!val &&
            val < ratingScale.value.doNotMeetExpectationsTo &&
            ratingScale.value.totalScoreFrom ===
              ratingScale.value.doNotMeetExpectationsFrom) ||
          (val == "0" &&
            ratingScale.value.doNotMeetExpectationsFrom !=
              ratingScale.value.doNotMeetExpectationsTo)
        );
      },
      doNotMeetExpectationsTo: val => {
        return (
          (!!val && val > ratingScale.value.doNotMeetExpectationsFrom) ||
          (val == "0" &&
            ratingScale.value.doNotMeetExpectationsFrom !=
              ratingScale.value.doNotMeetExpectationsTo)
        );
      },
    };

    const isValid = ref(true);
    const fieldScoreValidate = e => {
      if (
        !(
          e.key == "Backspace" ||
          e.key == "ArrowLeft" ||
          e.key == "ArrowRight" ||
          (/^\d+$/.test(e.key) &&
            e.target.value.length < 2 &&
            e.target.value != "0")
        )
      ) {
        e.preventDefault();
      }
      setTimeout(() => {
        refs.form.validate();
      }, 0);
    };

    const ratingScale = ref({ ..._.options });
    const initialScale = ref(JSON.parse(JSON.stringify(ratingScale.value)));

    const resetConfirm = ref(false);
    const scoresConfirm = ref(false);

    const isNeedConfirm = inject("isNeedConfirm");
    const saveFunction = inject("saveFunction");
    const showWarning = inject("showWarning");

    // Check for changes
    watch(
      () => ratingScale.value,
      val => {
        isValid.value = refs.form.validate();
        if (isValid.value)
          for (let key of Object.keys(val))
            if (ratingScale.value[key] !== initialScale.value[key]) {
              isNeedConfirm.value = true;
              return;
            }
      },
      { deep: true }
    );

    const reset = () => {
      ratingScale.value = JSON.parse(JSON.stringify(initialScale.value));
      isNeedConfirm.value = false;
      isValid.value = refs.form.validate();
    };

    const save = () =>
      store
        .dispatch("updateRatingScale", [_.profileId, ratingScale.value])
        .then(() => {
          initialScale.value = JSON.parse(JSON.stringify(ratingScale.value));
          isNeedConfirm.value = false;
          scoresConfirm.value = false;
        });
    saveFunction.value = save;

    return {
      fieldScoreValidate,
      resetConfirm,
      scoresConfirm,
      isNeedConfirm,
      showWarning,
      ratingScale,
      reset,
      rules,
      isValid,
      save,
    };
  },
  components: {
    ScoreSlider,
    Errors,
  },
};
</script>
