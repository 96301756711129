<template>
  <v-form
    class="form mx-auto pb-3"
    ref="form"
    style="position: relative; max-width: 100%"
  >
    <div class="scroll-wrapper" style="overflow-y: auto">
      <div>
        <p class="subtitle mb-1">От кого:</p>
        <v-select
          class="mb-2"
          dense
          outlined
          hide-details
          v-model="email.from"
          :items="recipients"
          label="Выберите адресата"
          :required="true"
          :rules="[val => !!val || '']"
          validate-on-blur
        ></v-select>
      </div>

      <div>
        <p class="subtitle mb-1">Копия:</p>
        <v-select
          class="mb-2"
          dense
          outlined
          hide-details
          v-model="email.copy"
          :items="recipients.filter(el => el != email.from)"
          multiple
          chips
          label="Выберите адресатов"
        >
          <template v-slot:selection="{ item }">
            <v-chip style="cursor: pointer" class="my-1">
              <span>{{ item }}</span>
            </v-chip>
          </template>
        </v-select>
      </div>

      <div class="mb-3">
        <p class="subtitle mb-1">Дата:</p>
        <v-menu
          ref="menudate"
          v-model="dateMenu"
          :close-on-content-click="false"
          :return-value.sync="email.date"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              v-model="email.date"
              label="Выберите дату"
              readonly
              :rules="[val => !!val || '']"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="email.date"
            :first-day-of-week="1"
            @input="$refs.menudate.save(email.date)"
          >
          </v-date-picker>
        </v-menu>
      </div>

      <div class="mb-3">
        <p class="subtitle mb-1">Время:</p>
        <v-menu
          ref="menutime"
          v-model="timeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="email.time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              hide-details
              v-model="email.time"
              label="Выберите время"
              readonly
              :rules="[val => !!val || '']"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timeMenu"
            format="24hr"
            v-model="email.time"
            full-width
            @click:minute="$refs.menutime.save(email.time)"
          >
          </v-time-picker>
        </v-menu>
      </div>

      <div class="mb-3">
        <p style="position: relative; margin-right: 60px">
          <span class="subtitle">Приоритет:</span>
          <v-checkbox
            class="field--inline"
            style="position: absolute; bottom: -2px"
            v-model="email.important"
          ></v-checkbox>
        </p>
      </div>

      <div>
        <p class="subtitle mb-1">Тема письма:</p>
        <v-text-field
          class="field-details--hidden"
          outlined
          v-model="email.title"
          type="text"
          :required="true"
          :rules="[val => !!val || '']"
          label="Введите тему письма"
        ></v-text-field>
      </div>

      <div>
        <p class="subtitle mb-1">Текст письма:</p>
        <v-textarea
          hide-details
          outlined
          no-resize
          v-model="email.text"
          type="text"
          :required="true"
          :rules="[val => !!val || '']"
          label="Введите текст письма"
          height="350"
        ></v-textarea>
      </div>

      <div class="mt-1">
        <div v-for="(file, idx) in documents" :key="idx">
          <div
            class="d-flex justify-space-between align-center"
            style="min-height: 36px"
          >
            <p class="subtitle">Вложение {{ idx + 1 }}:</p>
            <v-btn icon @click="documents.splice(idx, 1)">
              <v-icon color="#767676" style="font-size: 20px"
                >mdi-delete</v-icon
              >
            </v-btn>
          </div>
          <v-text-field
            class="mb-2"
            hide-details
            outlined
            v-model="file.name"
            type="text"
            :required="true"
            :rules="[val => !!val || '']"
            label="Введите название документа"
          ></v-text-field>
          <v-text-field
            class="mb-2"
            hide-details
            outlined
            v-model="file.link"
            type="text"
            :required="true"
            :rules="[val => !!val || '']"
            label="Введите адрес документа"
          ></v-text-field>
        </div>
      </div>

      <text-btn class="mt-1" icon="mdi-plus" @click="documents.push({})"
        >Добавить вложение</text-btn
      >
    </div>
    <div class="d-flex justify-space-between mt-7">
      <v-btn class="white--text primary flex-grow-1 mr-5" @click="cancel">
        Отменить
      </v-btn>
      <v-btn
        class="white--text primary flex-grow-1"
        @click="$refs.form.validate() && save()"
      >
        Сохранить
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";

export default {
  props: {
    editedEmail: {
      type: Object,
      default: () => {
        return {
          important: false,
        };
      },
    },
    profileId: String,
    isEdit: Boolean,
  },
  setup(_, { refs, root, emit }) {
    const store = root.$store;

    const recipients = computed(() => store.getters.getRecipientsNames);

    const email = ref(JSON.parse(JSON.stringify(_.editedEmail)));
    const documents = ref(
      JSON.parse(JSON.stringify(_.editedEmail.documents || []))
    );

    const changed = ref(false);
    watch(email.value, () => {
      changed.value = true;
    });

    const cancel = () => {
      if (!changed.value) {
        emit("close");
      } else {
        changed.value = false;
        email.value = JSON.parse(JSON.stringify(_.editedEmail));
        documents.value = JSON.parse(
          JSON.stringify(_.editedEmail.documents || [])
        );

        watch(email.value, () => {
          changed.value = true;
        });
      }
    };

    const save = () => {
      if (!refs.form.validate()) return;

      email.value.documents = documents.value;

      if (_.isEdit) {
        store.dispatch("updateProfileEmail", {
          profileId: _.profileId,
          email: email.value,
        });
      } else {
        store.dispatch("createProfileEmail", {
          profileId: _.profileId,
          email: email.value,
        });
      }
      emit("close");
    };

    const dateMenu = ref(false);
    const timeMenu = ref(false);

    return {
      changed,
      recipients,
      email,
      documents,
      cancel,
      save,
      dateMenu,
      timeMenu,
    };
  },
};
</script>
