<template>
  <div>
    <div class="d-flex justify-space-between">
      <text-btn small icon="mdi-arrow-left" @click="cancel()">Назад</text-btn>
    </div>
    <v-form class="form" ref="form">
      <div class="mb-3">
        <p class="subtitle mb-2 primary--text">Заголовок отчета:</p>
        <v-text-field
          hide-details
          outlined
          v-model="editedSettings.title"
          type="text"
          required
          :rules="[val => !!val || '']"
          label="Введите заголовок отчета"
        ></v-text-field>
      </div>

      <div class="mb-1">
        <p class="subtitle mb-2 primary--text">Вводная информация к отчету:</p>
        <v-textarea
          class="field-details--hidden"
          outlined
          dense
          v-model="editedSettings.introStatement"
          :no-resize="true"
          required
          label="Введите вводную информацию к отчету"
        ></v-textarea>
      </div>

      <list
        v-if="editedSettings.docName"
        class="mt-5"
        :data="[{ id: 1, name: editedSettings.docName }]"
        :cases="{ title: null }"
      ></list>

      <div class="mb-3">
        <v-menu
          v-model="docMenu"
          top
          :close-on-content-click="false"
          :nudge-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="text-btn" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon
              >{{
                editedSettings.docName ? "Изменить файл" : "Прикрепить файл"
              }}</v-btn
            >
          </template>
          <v-card class="pa-3">
            <v-form ref="docForm">
              <div class="mb-3">
                <p class="subtitle mb-1">Название документа:</p>
                <v-text-field
                  hide-details
                  outlined
                  v-model="docData.name"
                  type="text"
                  :rules="[val => !!val]"
                  required
                  label="Введите название документа"
                ></v-text-field>
              </div>

              <div class="mb-3">
                <p class="subtitle mb-1">Ссылка на документ:</p>
                <v-text-field
                  hide-details
                  outlined
                  v-model="docData.link"
                  type="text"
                  :rules="[val => !!val]"
                  required
                  label="Вставьте ссылку на документ"
                ></v-text-field>
              </div>
            </v-form>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="docMenu = false"> Отменить </v-btn>
              <v-btn color="primary" text @click="addDoc"> Сохранить </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>

      <div class="mb-3">
        <p class="subtitle mb-2 primary--text">
          Название раздела "Выводы по итогам центра оценки или развития":
        </p>
        <v-text-field
          hide-details
          outlined
          v-model="editedSettings.assessmentConclusionSectionName"
          type="text"
          required
          :rules="[val => !!val || '']"
          label="Введите название раздела"
        ></v-text-field>
      </div>

      <div class="mb-3">
        <p class="subtitle mb-2 primary--text">Итоговый вывод:</p>

        <v-select
          class="mb-3"
          dense
          outlined
          item-text="name"
          item-value="value"
          label="Выберите тип вывода"
          :items="conclusionTypes"
          v-model="selectedConclusionType"
          hide-details
        >
        </v-select>

        <v-textarea
          class="field-details--hidden"
          outlined
          dense
          v-model="editedSettings[selectedConclusionType]"
          :no-resize="true"
          required
          label="Введите описание завершения отчета"
        ></v-textarea>
      </div>

      <div class="mb-3">
        <p class="subtitle mb-2 primary--text">
          Название раздела "Выводы по компетенциям":
        </p>
        <v-text-field
          hide-details
          outlined
          v-model="editedSettings.competencesConclusionSectionName"
          type="text"
          required
          :rules="[val => !!val || '']"
          label="Введите название раздела"
        ></v-text-field>
      </div>

      <div class="mb-3">
        <p class="subtitle mb-2 primary--text">Завершение отчета:</p>
        <v-textarea
          class="field-details--hidden"
          outlined
          dense
          v-model="editedSettings.completionStatement"
          :no-resize="true"
          required
          label="Введите описание завершения отчета"
        ></v-textarea>
      </div>

      <div class="d-flex justify-space-between mt-7">
        <confirm
          :extDialog="cancelConfirm"
          @confirm="cancel(), (cancelConfirm = false)"
          @cancel="cancelConfirm = false"
          text="Отменить изменения?"
        >
          <v-btn
            class="white--text primary flex-grow-1 mr-5"
            @click="isChanged ? (cancelConfirm = true) : cancel()"
          >
            Отменить
          </v-btn>
        </confirm>

        <confirm
          :extDialog="saveConfirm"
          @confirm="save(), (saveConfirm = false)"
          @cancel="saveConfirm = false"
          text="Cохранить изменения?"
        >
          <v-btn
            class="white--text primary flex-grow-1"
            @click="isChanged && $refs.form.validate() && (saveConfirm = true)"
          >
            Сохранить
          </v-btn>
        </confirm>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref, watch, onBeforeUnmount, inject } from "@vue/composition-api";

export default {
  props: {
    reportSettings: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { emit, refs, root }) {
    const store = root.$store;

    const editedSettings = ref({ ..._.reportSettings });

    const conclusionTypes = [
      {
        name: "Превосходит ожидания",
        value: "exceedsExpectationsConclusion",
      },
      {
        name: "Соответствует ожиданиям",
        value: "meetExpectationsConclusion",
      },
      {
        name: "Не соответствует ожиданиям",
        value: "doNotMeetExpectationsConclusion",
      },
    ];

    const selectedConclusionType = ref(conclusionTypes[0].value);

    const docMenu = ref(false);
    const docData = ref({
      name: "",
      link: "",
    });
    watch(docMenu, () => {
      refs.docForm && refs.docForm.resetValidation();
      docData.value.name = editedSettings.value.docName;
      docData.value.link = editedSettings.value.docLink;
    });
    const addDoc = () => {
      if (!refs.docForm.validate()) return;

      editedSettings.value.docName = docData.value.name;
      editedSettings.value.docLink = docData.value.link;
      docMenu.value = false;
    };

    const saveConfirm = ref(false);
    const cancelConfirm = ref(false);

    const isChanged = ref(false);

    watch(editedSettings.value, () => {
      isChanged.value = true;
    });

    const cancel = () => {
      isChanged.value = false;
      emit("cancel");
    };

    const save = () => {
      isChanged.value = false;
      emit("save", editedSettings.value);
    };

    const leaveSave = () =>
      store.dispatch("updateReportSettings", editedSettings.value);

    onBeforeUnmount(() => {
      if (isChanged.value) {
        inject("save-confirm")(leaveSave);
      }
    });

    return {
      editedSettings,
      conclusionTypes,
      selectedConclusionType,
      docMenu,
      docData,
      addDoc,
      saveConfirm,
      cancelConfirm,
      isChanged,
      cancel,
      save,
    };
  },
};
</script>
