<template>
  <div>
    <v-form class="form" ref="form">
      <div v-for="item in materialTypes" :key="item.type">
        <list
          :cases="{
            title: item.title,
            add: 'Добавить документ',
          }"
          :data="materials[item.type]"
          edit
          add
          remove
          @add="
            (editedMaterial = $event), switchToggle(true, `create-${item.type}`)
          "
          @edit="
            (editedMaterial = $event), switchToggle(true, `edit-${item.type}`)
          "
          @remove="removeMaterial"
        ></list>
      </div>
    </v-form>
    <modal :dialog="toggle" :width="toggle.width">
      <div v-for="item in materialTypes" :key="item.type">
        <MaterialForm
          v-if="toggle.view == `create-${item.type}`"
          @close="switchToggle(false, toggle.view)"
          @save="addMaterial($event, item.type)"
        />
        <MaterialForm
          v-else-if="toggle.view == `edit-${item.type}`"
          :editedDoc="editedMaterial"
          @close="switchToggle(false, toggle.view)"
          @save="updateMaterial"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import { ref, reactive, computed } from "@vue/composition-api";
import { useModalToggle } from "@/use/helpers";
import MaterialForm from "./materials/MaterialForm";

export default {
  props: ["profile"],
  setup(_, { root }) {
    const store = root.$store;

    const { toggle, switchToggle } = useModalToggle();

    const materialTypes = [
      {
        type: "FOR_PARTICIPANT",
        title: "Материалы для участника оценки",
      },
      {
        type: "FOR_EXPERT",
        title: "Материалы для эксперта",
      },
      {
        type: "FOR_LEADER",
        title: "Материалы для руководителя",
      },
      {
        type: "FOR_OBSERVER",
        title: "Материалы для наблюдателя",
      },
    ];

    const materials = reactive({
      FOR_PARTICIPANT: computed(() =>
        _.profile.materials.filter(
          el => el.materialType == materialTypes[0].type
        )
      ),
      FOR_EXPERT: computed(() =>
        _.profile.materials.filter(
          el => el.materialType == materialTypes[1].type
        )
      ),
      FOR_LEADER: computed(() =>
        _.profile.materials.filter(
          el => el.materialType == materialTypes[2].type
        )
      ),
      FOR_OBSERVER: computed(() =>
        _.profile.materials.filter(
          el => el.materialType == materialTypes[3].type
        )
      ),
    });

    const editedMaterial = ref({});

    const removeMaterial = item =>
      store
        .dispatch("removeMaterial", item.id)
        .then(() => (editedMaterial.value = {}));

    const updateMaterial = item =>
      store
        .dispatch("updateMaterial", item)
        .then(() => (editedMaterial.value = {}));

    const addMaterial = (item, view) => {
      item.profileId = _.profile.id;
      item.materialType = view;
      store.dispatch("createMaterial", item);
      editedMaterial.value = {};
    };

    return {
      toggle,
      switchToggle,
      materialTypes,
      materials,
      editedMaterial,
      removeMaterial,
      updateMaterial,
      addMaterial,
    };
  },
  components: {
    MaterialForm,
  },
};
</script>
