<template>
  <div class="mb-3 form" style="z-index: 101">
    <p class="subtitle mb-2 primary--text">Дата в симуляции:</p>
    <v-menu
      ref="menu"
      v-model="dateMenu"
      :close-on-content-click="false"
      :return-value.sync="simulationDate"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          dense
          hide-details
          v-model="simulationDate"
          label="Выберите дату"
          prepend-icon="mdi-calendar"
          readonly
          :rules="[val => !!val || '']"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="simulationDate"
        :first-day-of-week="1"
        year-icon="mdi-chevron-down"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateMenu = false"> Отменить </v-btn>
        <v-btn text color="primary" @click="save"> Сохранить </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";

export default {
  props: ["profileId", "date", "time"],
  setup(_, { refs, emit, root }) {
    const store = root.$store;

    const simulationDate = ref(_.date);
    const dateMenu = ref(false);

    const currentDateTime = computed(() => new Date().toISOString());

    const save = () => {
      if (_.time.beginTime && _.time.endTime && simulationDate.value) {
        store.dispatch("updateSimulationDateTime", {
          profileId: _.profileId,
          time: _.time,
          date: simulationDate.value,
        });
      }
      refs.menu.save(simulationDate.value);
      emit("date-saved", simulationDate.value);
    };

    return {
      simulationDate,
      dateMenu,
      currentDateTime,
      save,
    };
  },
};
</script>

<style>
.v-application--is-ltr .v-date-picker-title .v-date-picker-title__date {
  display: none;
}
.v-date-picker-title__year {
  order: 1;
  margin: 0 auto;
  font-size: 20px;
}
</style>
