<template>
  <div>
    <div class="mb-3 form">
      <p class="subtitle mb-2 primary--text">
        Время начала рабочего дня в симуляции:
      </p>
      <v-menu
        ref="start"
        v-model="timeMenuStart"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="simulationTime.beginTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            hide-details
            :disabled="!date"
            v-model="simulationTime.beginTime"
            label="Выберите время"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            :rules="[val => !!val || '']"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="timeMenuStart"
          format="24hr"
          v-model="simulationTime.beginTime"
          full-width
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="timeMenuStart = false">
            Отменить
          </v-btn>
          <v-btn text color="primary" @click="save"> Сохранить </v-btn>
        </v-time-picker>
      </v-menu>
    </div>
    <div class="mb-3 form">
      <p class="subtitle mb-2 primary--text">
        Время окончания рабочего дня в симуляции:
      </p>
      <v-menu
        ref="end"
        v-model="timeMenuEnd"
        :close-on-content-click="false"
        :disabled="!simulationTime.beginTime"
        :nudge-right="40"
        :return-value.sync="simulationTime.endTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            hide-details
            :disabled="!date"
            v-model="simulationTime.endTime"
            label="Выберите время"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            :rules="[val => !!val || '']"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="timeMenuEnd"
          format="24hr"
          v-model="simulationTime.endTime"
          full-width
          :min="simulationTime.beginTime"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="timeMenuEnd = false">
            Отменить
          </v-btn>
          <v-btn text color="primary" @click="save"> Сохранить </v-btn>
        </v-time-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";

export default {
  props: ["profileId", "time", "date"],
  setup(_, { refs, root }) {
    const store = root.$store;

    const simulationTime = ref(_.time);
    if (!_.time) {
      simulationTime.value = { beginTime: "", endTime: "" };
    }
    const timeMenuStart = ref(false);
    const timeMenuEnd = ref(false);

    const minTime = computed(() => {
      const date = new Date(_.date);
      const isTommorow = date.getTime() > new Date().getTime();
      return isTommorow ? null : new Date().toLocaleTimeString();
    });

    const save = () => {
      if (simulationTime.value.beginTime && simulationTime.value.endTime) {
        store.dispatch("updateSimulationDateTime", {
          profileId: _.profileId,
          time: simulationTime.value,
          date: _.date,
        });
      }

      refs.start.save(simulationTime.value.beginTime);
      refs.end.save(simulationTime.value.endTime);
      timeMenuStart.value = false;
      timeMenuEnd.value = false;
    };

    return {
      simulationTime,
      timeMenuStart,
      timeMenuEnd,
      minTime,
      save,
    };
  },
};
</script>
