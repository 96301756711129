<template>
  <div class="mb-3">
    <p class="subtitle mb-1 primary--text">Встречи:</p>
    <v-card
      v-if="events.length"
      class="mb-2 panels"
      style="max-width: 570px; max-height: 400px; overflow: auto"
      elevation="3"
    >
      <v-expansion-panels accordion elevation="0" multiple>
        <v-list-item-group style="width: 100%">
          <v-expansion-panel v-for="(item, i) in events" :key="i">
            <v-expansion-panel-header class="py-2">
              <div
                class="d-flex justify-space-between align-center"
                style="width: calc(100% - 30px)"
              >
                <p style="max-width: calc(100% - 80px)">{{ item.title }}</p>
                <div class="flex-shrink-0">
                  <v-btn icon @click.stop="editEvent(item.id)">
                    <v-icon color="#767676" style="font-size: 16px"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="(selectedId = item.id), (confirmDialog = true)"
                  >
                    <v-icon color="#767676" style="font-size: 16px"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list class="py-0" disabled>
                <v-list-item class="indicator--active">
                  <div class="flex-grow-1">
                    <div class="d-flex justify-space-between mb-2">
                      <p>От: {{ item.from }}</p>
                      <div>
                        <p>
                          {{ item.date }},
                          {{ item.startTime + " - " + item.endTime }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex justify-space-between mb-4">
                      <p class="flex-shrink-0">Копия:</p>
                      <p class="px-1 flex-grow-1">
                        {{ item.copy.filter(Boolean).join("; ") }}
                      </p>
                      <div class="text-right flex-shrink-0">
                        <p>Вложений: {{ item.documents.length }}</p>
                      </div>
                    </div>
                    <p v-html="replacer(item.text)"></p>
                  </div>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-list-item-group>
      </v-expansion-panels>
      <confirm
        v-show="false"
        :extDialog="confirmDialog"
        @cancel="confirmDialog = false"
        @confirm="removeEvent(selectedId), (confirmDialog = false)"
      >
      </confirm>
    </v-card>
    <text-btn icon="mdi-plus" @click="switchToggle(true, 'create')"
      >Добавить встречу</text-btn
    >
    <modal :dialog="toggle" :width="800" :persistent="true">
      <SimulationEventsForm
        v-if="toggle.view == 'create'"
        :profileId="profileId"
        @close="switchToggle(false, 'create')"
      />
      <SimulationEventsForm
        v-if="toggle.view == 'edit'"
        :editedEvent="event"
        :isEdit="true"
        :profileId="profileId"
        @close="switchToggle(false, 'edit')"
      />
    </modal>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { useModalToggle, useLineBreakReplacer } from "@/use/helpers";
import SimulationEventsForm from "./SimulationEventsForm";

export default {
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    profileId: String,
  },
  setup(_, { root }) {
    const store = root.$store;

    const { toggle, switchToggle } = useModalToggle();
    const { replacer } = useLineBreakReplacer();

    const confirmDialog = ref(false);

    const selectedId = ref(-1);

    const event = ref({});

    const editEvent = id => {
      event.value = _.events.find(el => el.id == id);
      switchToggle(true, "edit");
    };

    const removeEvent = id =>
      store.dispatch("removeProfileEvent", {
        profileId: _.profileId,
        eventId: id,
      });

    return {
      confirmDialog,
      selectedId,
      toggle,
      switchToggle,
      replacer,
      event,
      editEvent,
      removeEvent,
    };
  },
  components: {
    SimulationEventsForm,
  },
};
</script>
