<template>
  <div class="mt-3">
    <div class="d-flex">
      <div>
        <v-card max-width="250" class="flex-shrink-0 mr-6">
          <v-list class="py-0" :disabled="!isEdit">
            <v-list-item-group
              color="primary"
              v-model="selectedIndex"
              mandatory
            >
              <v-list-item v-for="tab in tabs" :key="tab.name" class="py-2">
                <v-list-item-content>
                  <v-list-item-title>{{ tab.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
      <div class="flex-grow-1">
        <div v-if="!isSubtab" class="d-flex justify-space-between">
          <text-btn small icon="mdi-arrow-left" @click="$emit('back')"
            >К списку профилей</text-btn
          >
          <text-btn
            v-if="selectedIndex < tabs.length - 1 && isEdit"
            small
            right
            icon="mdi-arrow-right"
            @click="selectedIndex++"
            >Далее</text-btn
          >
        </div>
        <component
          :is="currentTab"
          :profile="profile"
          :isEdit="isEdit"
          @profile-created="$emit('profile-created', $event)"
          @subtab-entered="isSubtab = true"
          @subtab-left="isSubtab = false"
        />
      </div>
    </div>

    <!--Shown on switch tab if changed-->
    <confirm
      :extDialog="isConfirmOpened"
      text="Сохранить изменения?"
      @confirm="proceed"
      @cancel="moveWithoutSave"
    />
  </div>
</template>

<script>
import { useTabs } from "@/use/helpers";
import { ref, watch, computed, provide } from "@vue/composition-api";
import Name from "./profile/tabs/Name";
import Positions from "./profile/tabs/Positions";
import Competences from "./profile/tabs/Competences";
import Simulation from "./profile/tabs/Simulation";
import Tasks from "./profile/tabs/Tasks";
import Report from "./profile/tabs/Report";
import Materials from "./profile/tabs/Materials";
import Questionnaire from "./profile/tabs/Questionnaire";

export default {
  props: {
    profile: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(_, { root }) {
    const isEdit = computed(() => !!_.profile.id);

    const isSubtab = ref(false);
    const tabs = [
      {
        name: "Name",
        label: "Название, описание, продолжительность",
      },
      {
        name: "Competences",
        label: "Модель компетенций",
      },
      {
        name: "Simulation",
        label: "Симуляция рабочего дня",
      },
      {
        name: "Tasks",
        label: "Задания для оценки",
      },
      {
        name: "Report",
        label: "Отчет",
      },
      {
        name: "Materials",
        label: "Материалы для подготовки",
      },
      {
        name: "Questionnaire",
        label: "Анкета для участника",
      },
    ];

    const selectedIndex = ref(0);
    const { currentTab, selectTab } = useTabs(tabs[selectedIndex.value].name);
    const targetTabIndex = ref(-1);
    const moveToTab = index => {
      selectTab(tabs[index].name);
      isSubtab.value = false;
    };
    const moveWithoutSave = () => {
      isConfirmOpened.value = false;
      isNeedConfirm.value = false;
      moveToTab(targetTabIndex.value);
    };
    watch(selectedIndex, val => {
      targetTabIndex.value = val;
      if (isNeedConfirm.value) isConfirmOpened.value = true;
      else moveToTab(val);
    });

    const isConfirmOpened = ref(false);
    const isNeedConfirm = ref(false);
    provide("isNeedConfirm", isNeedConfirm);
    const saveFunction = ref(null);
    provide("saveFunction", saveFunction);
    const proceed = () => {
      saveFunction.value(true);
      isConfirmOpened.value = false;
      moveToTab(targetTabIndex.value);
    };
    const showWarning = () => root.$toast.warning("Изменения не внесены");
    provide("showWarning", showWarning);

    return {
      isSubtab,
      isEdit,
      tabs,
      currentTab,
      selectTab,
      selectedIndex,
      isConfirmOpened,
      moveWithoutSave,
      proceed,
    };
  },
  components: {
    Name,
    Positions,
    Competences,
    Simulation,
    Tasks,
    Report,
    Materials,
    Questionnaire,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .v-item--active {
    background-color: $mainColor !important;
    color: #fff;
  }
}
</style>
