<template>
  <div>
    <ProfilesList
      v-if="!isProfileSelected"
      :profiles="profiles"
      @profile-selected="selectProfile"
      @profile-added="selectProfile"
    />
    <ProfileMenu
      v-else
      :profile="selectedProfile"
      @back="unselectProfile()"
      @profile-created="selectProfile"
    />
    <preloader v-if="loading" />
  </div>
</template>

<script>
import ProfilesList from "./assets/metodology/ProfilesList";
import ProfileMenu from "./assets/metodology/ProfileMenu";
import { ref, computed, onMounted, inject } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const loading = inject("loading");

    const profiles = computed(() => store.getters.getProfiles);

    const isProfileSelected = ref(false);

    const selectedProfile = computed(() => store.getters.getCurrentProfile);
    const selectProfile = (item = {}) => {
      store.commit("setCurrentProfile", item);
      isProfileSelected.value = true;
    };
    const unselectProfile = () => {
      store.commit("setCurrentProfile", {});
      isProfileSelected.value = false;
    };

    onMounted(() => localStorage.setItem("lastTab", "Metodology"));

    return {
      loading,
      profiles,
      isProfileSelected,
      selectedProfile,
      selectProfile,
      unselectProfile,
    };
  },
  components: {
    ProfilesList,
    ProfileMenu,
  },
};
</script>

<style lang="scss" scoped></style>
