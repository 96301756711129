<template>
  <div>
    <div class="mb-3" style="max-width: 570px">
      <p class="subtitle mb-1 primary--text">Задания для оценки:</p>
      <v-card
        v-if="tasks.length"
        class="mb-1"
        elevation="3"
        style="max-height: 300px; overflow: auto"
      >
        <v-list dense class="py-0">
          <v-list-item class="brd-b" v-for="task in tasks" :key="task.id">
            <v-list-item-content>
              <div
                class="d-flex justify-space-between align-center"
                style="width: 100%"
              >
                <p style="max-width: calc(100% - 80px)">{{ task.name }}</p>

                <div class="flex-shrink-0">
                  <v-btn icon @click.stop="$emit('task-selected', task)">
                    <v-icon color="#767676" style="font-size: 16px"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="(selectedId = task.id), (confirmDialog = true)"
                  >
                    <v-icon color="#767676" style="font-size: 16px"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <confirm
          v-show="false"
          :extDialog="confirmDialog"
          @cancel="confirmDialog = false"
          @confirm="removeTask(selectedId), (confirmDialog = false)"
        >
        </confirm>
      </v-card>
      <text-btn icon="mdi-plus" @click="$emit('task-selected', {})"
        >Добавить задание</text-btn
      >
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { useModalToggle } from "@/use/helpers";

export default {
  props: ["tasks"],
  setup(_, { root }) {
    const store = root.$store;
    const { toggle, switchToggle } = useModalToggle();

    const confirmDialog = ref(false);

    const selectedId = ref(-1);

    const removeTask = id => store.dispatch("removeProfileTask", id);

    return {
      confirmDialog,
      selectedId,
      toggle,
      switchToggle,
      removeTask,
    };
  },
};
</script>
