var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-3",style:({
      maxWidth: '570px',
      paddingBottom: '50px',
    })},[_c('list',{attrs:{"cases":{
        title: 'Комплект документов для участника оценки',
        add: 'Добавить документ',
      },"data":_vm.docs.PARTICIPANT,"edit":"","add":"","remove":"","help":_vm.helpTexts.PARTICIPANT},on:{"add":function($event){return _vm.switchToggle(true, 'PARTICIPANT')},"edit":_vm.selectDoc,"remove":_vm.removeDoc}}),_c('list',{attrs:{"cases":{
        title: 'Комплект документов для эксперта по оценке',
        add: 'Добавить документ',
      },"data":_vm.docs.EXPERT,"edit":"","add":"","remove":"","help":_vm.helpTexts.EXPERT},on:{"add":function($event){return _vm.switchToggle(true, 'EXPERT')},"edit":_vm.selectDoc,"remove":_vm.removeDoc}}),_c('list',{attrs:{"cases":{
        title: 'Комплект документов для руководителя',
        add: 'Добавить документ',
      },"data":_vm.docs.LEADER,"edit":"","add":"","remove":"","help":_vm.helpTexts.LEADER},on:{"add":function($event){return _vm.switchToggle(true, 'LEADER')},"edit":_vm.selectDoc,"remove":_vm.removeDoc}}),_c('list',{attrs:{"cases":{
        title: 'Комплект документов для наблюдателя',
        add: 'Добавить документ',
      },"data":_vm.docs.OBSERVER,"edit":"","add":"","remove":"","help":_vm.helpTexts.OBSERVER},on:{"add":function($event){return _vm.switchToggle(true, 'OBSERVER')},"edit":_vm.selectDoc,"remove":_vm.removeDoc}})],1),_c('modal',{attrs:{"dialog":_vm.toggle,"width":_vm.toggle.width}},[_c('SimulationDocsForm',{key:_vm.doc.id || Date.now(),attrs:{"profileId":_vm.profile.id,"editedDoc":_vm.doc},on:{"close":function($event){return _vm.switchToggle(false)},"save":_vm.saveDoc}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }