<template>
  <v-form class="form" ref="form">
    <div>
      <p class="subtitle mb-2 primary--text">Текущие должности:</p>
      <v-select
        dense
        outlined
        v-model="currentPositions"
        item-text="name"
        item-value="id"
        return-object
        :items="allPositions"
        multiple
        chips
        :required="true"
        :rules="[val => !!val.length || '']"
        validate-on-blur
        @change="isChanged = true"
      >
        <template v-slot:selection="{ item }">
          <v-chip style="cursor: pointer" class="my-1">
            <span>{{ item.name }}</span>
          </v-chip>
        </template>
      </v-select>
    </div>
    <div>
      <p class="subtitle mb-2 primary--text">Целевые должности:</p>
      <v-select
        dense
        outlined
        v-model="targetPositions"
        item-text="name"
        item-value="id"
        return-object
        :items="allPositions"
        multiple
        chips
        :required="true"
        :rules="[val => !!val.length || '']"
        validate-on-blur
        @change="isChanged = true"
        ><template v-slot:selection="{ item }">
          <v-chip style="cursor: pointer" class="my-1">
            <span>{{ item.name }}</span>
          </v-chip>
        </template></v-select
      >
    </div>
    <div class="d-flex justify-space-between">
      <v-btn class="white--text primary flex-grow-1 mr-5" @click="cancel">
        Отменить
      </v-btn>
      <v-btn
        class="white--text primary flex-grow-1"
        @click="isChanged && $refs.form.validate() && save()"
      >
        Сохранить
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { ref, onBeforeUnmount, inject } from "@vue/composition-api";

export default {
  props: ["profile"],
  setup(_, { root }) {
    const store = root.$store;
    const allPositions = store.getters.getPositions;

    const isChanged = ref(false);

    const currentPositions = ref(_.profile.currentPositions);
    const targetPositions = ref(_.profile.targetPositions);

    const cancel = () => {
      currentPositions.value = _.profile.currentPositions;
      targetPositions.value = _.profile.targetPositions;
      isChanged.value = false;
    };

    const save = () => {
      isChanged.value = false;

      store.dispatch("updateProfilesPositions", {
        profileId: _.profile.id,
        positions: {
          currentPositions: currentPositions.value,
          targetPositions: targetPositions.value,
        },
      });
    };

    onBeforeUnmount(() => {
      if (isChanged.value) {
        inject("save-confirm")(save);
      }
    });

    return {
      currentPositions,
      allPositions,
      isChanged,
      targetPositions,
      cancel,
      save,
    };
  },
};
</script>

<style lang="scss"></style>
