<template>
  <div class="form">
    <list
      v-if="!editedReportSettings"
      :data="roles"
      edit
      :cases="{
        title: null,
      }"
      @edit="editReport"
    >
    </list>
    <ReportSettingsForm
      v-else
      :report-settings="editedReportSettings"
      @cancel="cancelEdit"
      @save="saveEdit"
    />
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import ReportSettingsForm from "./report/ReportSettingsForm";
import useReport from "@/use/report";

export default {
  components: {
    ReportSettingsForm,
  },
  props: ["profile"],
  setup(_, { root, emit }) {
    const store = root.$store;

    const { reportModel } = useReport();

    const editedReportSettings = ref(null);
    const profileReportSettingsRoles = computed(
      () => store.getters.getProfileReportSettingsRoles
    );

    const roles = computed(() =>
      profileReportSettingsRoles.value.map(role => ({
        id: role,
        name: store.getters.getRoleTitle(role),
      }))
    );

    const editReport = item => {
      emit("subtab-entered");
      editedReportSettings.value =
        _.profile.reportSettings.find(el => el.role == item.id) || reportModel;

      editedReportSettings.value.profileId = _.profile.id;
      editedReportSettings.value.role = item.id;
    };

    const cancelEdit = () => {
      editedReportSettings.value = null;
      emit("subtab-left");
    };

    const saveEdit = async data => {
      store.dispatch("updateReportSettings", data);
      cancelEdit();
    };

    return {
      editedReportSettings,
      roles,
      editReport,
      saveEdit,
      cancelEdit,
    };
  },
};
</script>
