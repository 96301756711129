<template>
  <v-form
    class="form mx-auto competence-form"
    ref="form"
    :style="{ position: 'relative', 'max-width': '100%' }"
  >
    <div>
      <p class="subtitle mb-2">
        Компетенция {{ competence.indexNumber || index }}:
      </p>
      <v-text-field
        class="field-details--hidden"
        outlined
        v-model="competence.name"
        type="text"
        :required="true"
        :rules="[val => !!val || '']"
        label="Введите название компетенции"
      ></v-text-field>
    </div>
    <div style="max-height: 350px; overflow-y: auto" class="scroll-wrapper">
      <div
        v-for="(indicator, idx) in competence.indicators"
        :key="idx"
        class="indicator"
      >
        <div
          class="d-flex justify-space-between align-center"
          style="min-height: 36px"
        >
          <p class="subtitle">
            Индикатор {{ competence.indexNumber || index }}.{{ idx + 1 }}:
          </p>
          <v-btn
            v-if="competence.indicators.length > 1"
            icon
            @click="onDeleteClick(idx)"
          >
            <v-icon color="#767676" style="font-size: 20px">mdi-delete</v-icon>
          </v-btn>
        </div>
        <v-textarea
          class="field-details--hidden"
          outlined
          dense
          v-model="indicator.name"
          :no-resize="true"
          :required="true"
          :rules="[val => !!val || '']"
          label="Введите текст индикатора"
        ></v-textarea>
      </div>
    </div>
    <text-btn icon="mdi-plus" @click="scrollTo">Добавить индикатор</text-btn>
    <div class="d-flex justify-space-between mt-1">
      <v-btn class="white--text primary flex-grow-1 mr-5" @click="cancel">
        Отменить
      </v-btn>
      <v-btn
        class="white--text primary flex-grow-1"
        @click="$refs.form.validate() && changed && save()"
      >
        Сохранить
      </v-btn>
    </div>

    <confirm
      :extDialog="isRemoveConfirmShown"
      text="При удалении компетенции или индикатора они также удалятся в заданиях для оценки. Продолжить?"
      @cancel="isRemoveConfirmShown = false"
      @confirm="onRemoveConfirm"
    >
    </confirm>
  </v-form>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: {
    editedCompetence: {
      type: Object,
      default: () => {
        return {
          name: "",
          indicators: [{ name: "" }],
        };
      },
    },
    index: Number,
  },
  setup(_, { refs, emit }) {
    const changed = ref(false);
    const competence = ref(JSON.parse(JSON.stringify(_.editedCompetence)));
    watch(competence.value, () => {
      changed.value = true;
    });

    const isRemoveConfirmShown = ref(false);
    const removingIndex = ref(-1);

    const onDeleteClick = id => {
      isRemoveConfirmShown.value = true;
      removingIndex.value = id;
    };
    const onRemoveConfirm = () => {
      competence.value.indicators.splice(removingIndex.value, 1);
      isRemoveConfirmShown.value = false;
      removingIndex.value = -1;
    };

    const cancel = () => {
      if (!changed.value) {
        emit("close");
      } else {
        changed.value = false;
        competence.value = JSON.parse(JSON.stringify(_.editedCompetence));
        watch(competence.value, () => {
          changed.value = true;
        });
      }
    };

    const save = async () => {
      if (!refs.form.validate()) return;

      emit("competence-saved", {
        id: competence.value.id,
        name: competence.value.name,
        indicators: competence.value.indicators.map((el, index) => ({
          name: el.name,
          indexNumber: index + 1,
        })),
      });
      emit("close");
    };

    const scrollTo = () => {
      competence.value.indicators.push({});
      setTimeout(() => {
        const block = document.querySelector(".scroll-wrapper");
        let y = block.scrollTop;
        const delay = 300;
        const dest = block.scrollHeight - block.offsetHeight;
        const interval = 1000 / 24;
        const delta = (dest - y) * (interval / delay);
        (function scroll() {
          if (y < dest) {
            y += delta;
            block.scrollTop = y;
            setTimeout(scroll, interval);
          }
        })();
      }, 0);
    };

    return {
      changed,
      competence,
      isRemoveConfirmShown,
      removingIndex,
      onDeleteClick,
      onRemoveConfirm,
      cancel,
      save,
      scrollTo,
    };
  },
};
</script>

<style lang="scss" scoped></style>
